.trending {
    $gap: ac(150px, 70px);
    margin-top: $gap;

    &__title-box {
		display: flex;
		flex-direction: column;
	}

	&__search {
		position: relative;
        margin-top: ac(39px, 25px);
        max-width: 500px;
		width: 100%;
	}

	&__input {
		width: 100%;
		padding: 7px 60px 7px ac(30px, 15px);
		background: var(--white);
		border: 1px solid var(--golden);
		border-radius: 31px;
		line-height: ac(46px, 35px);
	}

	&__btn {
		position: absolute;
		right: 7px;
		top: 7px;
        width: ac(48px, 37px);
        height: ac(48px, 37px);

        .icon-arrow {
            line-height: ac(48px, 37px);
            font-size: 21px;
        }
	}

    &__card-box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
		margin-top: ac(59px, 30px);
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: relative;
        padding: ac(39px, 20px) ac(30px, 15px);
        width: calc(100% / 3 - 15px);
        min-height: ac(307px, 240px);
        border-radius: 40px;
        border: 1px solid var(--tuna);
        background-color: var(--white);
        transition: .3s ease, transform .4s ease !important;
        overflow: hidden;

        &:hover {
            background-color: var(--alice);
        }

        &:nth-child(1) {
            .trending__icon {
                width: ac(113px, 90px);
                height: ac(102px, 80px);
            }
        }

        &:nth-child(2) {
            .trending__icon {
                width: ac(78px, 80px);
                height: ac(87px, 70px);
            }
        }

        &:nth-child(3) {
            .trending__icon {
                width: ac(74px, 80px);
                height: ac(85px, 70px);
            }
        }

        &:nth-child(4) {
            .trending__icon {
                width: ac(78px, 80px);
                height: ac(82px, 70px);
            }
        }

        &:nth-child(5) {
            .trending__icon {
                width: ac(78px, 80px);
                height: ac(88px, 70px);
            }
        }

        &:nth-child(6) {
            .trending__icon {
                width: ac(78px, 80px);
                height: ac(81px, 70px);
            }
        }

        .btn {
            margin-top: auto;
        }
    }

    &__title {
        margin-bottom: ac(22px, 10px);
        font-weight: 700;
        font-size: ac(37px, 26px);
        line-height: ac(45px, 34px);
    }

    &__descr {
        margin-bottom: 18px;
        font-size: ac(21px, 16px);
        line-height: ac(26px, 20px);
        max-height: calc(ac(26px, 20px) * 5);
        @mixin max-line-length 5;
    }

    &__icon {
        position: absolute;
        right: ac(18px, 10px, 320, 1440);
        bottom: 0;

        img {
            object-position: right bottom;
            object-fit: contain;
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}