.ic-popup {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    display: flex;

    .choices {
        .choices__list .simplebar-scrollbar:before {
            background-color: var(--golden);
            border-radius: 0;
        }

        .simplebar-track.simplebar-vertical {
            width: 5px;
            border-radius: 0;
            bottom: 0;
            top: 0;
        }
    }

    &-registration {
        .ic-popup-container {
            .simplebar-track {
                background-color: rgba(8, 2, 35, .2);
            }
            
            .simplebar-scrollbar::before {
                background-color: var(--tuna);
            }


        }
    }

    &-bg {
        position: absolute;
        inset: 0;
        background: var(--tuna);
        opacity: 0.5;
        z-index: 0;
    }

    &-close-btn {
        background: var(--tuna);
        color: var(--white);
        width: 29px;
        height: 29px;
        display: flex;
        line-height: normal !important;
        justify-content: center;
        align-items: center;
        font-size: 12.37px;
        border-radius: 14px;
        position: absolute;
        right: ac(28px, 10px);
        top: ac(29px, 10px);
        transition: 0.3s;
        cursor: pointer;

        i {
            line-height: normal;
        }

        &:hover {
            background: var(--blue) !important;
        }
    }

    &-container {
        width: 1016px;
        max-width: 100%;
        background: var(--white);
        position: relative;
        z-index: 1;
        margin: 0 auto;
        border: 1px solid var(--tuna);
        border-radius: ac(40px, 20px, 375, 1440);
        padding: ac(80px, 55px) ac(103px, 15px) ac(72px, 30px) ac(103px, 15px);
        align-self: center;
        max-height: 85vh;
        display: flex;

        .simplebar-track {
            top: 40px;
            bottom: 40px;
			width: 6px;
			background-color: rgba(255, 255, 255, .2);
			border-radius: 7px;
		}

		.simplebar-scrollbar::before {
			background-color: var(--white);
            border-radius: 7px;
		}
    }

    &-form-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 16px;
        margin-bottom: ac(36px, 25px);
    }

    a {
        color: var(--golden);

        &.blue {
            color: var(--blue);
        }

        &:hover {
            text-decoration: underline;
        }
    }

    h2 {
        margin-bottom: ac(31px, 20px);
    }

    h3 {
        font-size: ac(27px, 20px);
        margin-bottom: ac(21px, 15px);
        font-weight: 600;
    }

    &-offices-title{
        margin-top: ac(50px, 25px);
        margin-bottom: ac(28px, 15px)!important;
    }

    .ic-popup-mail-link{
        color: var(--white);
        text-decoration: none;
        font-size: ac(27px, 18px);
        font-weight: bold;
        
        &:hover{
            color: var(--golden);
            text-decoration: none;
        }
    }

    .offices-list{
        display: grid;
        grid-template-columns: repeat(4,1fr);
        margin-bottom: 19px;
        grid-gap: 25px;
        padding-right: ac(8px, 0px);

        a{
            color: var(--white);
            text-decoration: none;
            font-size: 13px;
            line-height: 153%;

            &:hover{
                color: var(--golden);
            }
        }

        li{
            h3{
                width: fit-content;
                min-width: 0;
                margin-bottom: 9px;
                padding-bottom: 0;
                line-height: 1.66;
            }

            &>a{
                margin-top: 5px;
                font-size: 14px;
            }
        }
        @media (max-width: 768px){
            grid-template-columns: 1fr 1fr;
          }
        @media (max-width: 450px){
            grid-template-columns: 1fr;
          }
    }
}

.ic-popup.success {
    display: flex;
    align-items: center;
    padding: 100px 10px;

    .ic-popup {
        &-container {
            background: var(--tuna);
            max-height: 100%;
            height: auto;
        }
    }

    h2 {
        color: var(--golden);
        margin-bottom: ac(33px, 25px);
    }

    h3 {
        color: var(--white);
        font-size: ac(37px, 20px);
        margin-bottom: 0;
        font-weight: 700;
    }

    .ic-popup-close-btn {
        background: var(--white);
        color: var(--tuna);
    }
}

.ic-popup.ic-popup-refer {
    .ic-popup {
        &-container {
            background: var(--tuna);
            color: var(--white);
        }

        &-close-btn {
            background: var(--white);
            color: var(--tuna);
        }
    }
}

.upload-btn {
    padding: 5px 0 34px;
    display: flex;

    label {
        cursor: pointer;
        color: inherit;
        display: flex;
        align-items: center;
        gap: 20px;

        &:hover {
            text-decoration: underline;
        }

        input {
            display: none;
        }
    }

    div {
        min-height: 62px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--golden);
        padding: 5px 10px;
        border-radius: 31px;
        min-width: 141px;
    }
}

.checkbox-wr {
    cursor: pointer;
    font-size: 13px;
    display: block;
    margin-bottom: 3px;
    width: fit-content;
    transition: opacity 0.3s;
    font-weight: 400;

    &:hover{
        opacity: 0.8;
    }

   &--apply {
       a {
           color: var(--blue);
           transition: .3s ease;

           &:hover {
               color: var(--golden);
           }
       }
   }

    &:last-of-type {
        margin-bottom: 0;
    }

    & > span {
         display: block;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            margin-right: 10px;
            width: 14px;
            height: 14px;
            border: 1px solid var(--tuna);
            border-radius: 2px;
            margin-bottom: -2px;
        }
    }

    input:checked + span {
        &::before {
            background: var(--blue);
        }
    }

    &.checkbox-wr-white {
        & > span {
            position: relative;

            &::before {
                border: 1px solid var(--white);

            }
        }
    }
}

.upload-status {
    font-weight: 400;
}

.offices-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 15px;
    grid-row-gap: 51px;

    li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    h3 {
        font-size: ac(27px, 25px);
        padding-bottom: 1px;
        border-bottom: 2px solid var(--blue);
        display: inline-block;
        align-self: flex-start;
        min-width: 168px;
        margin-bottom: 24px;
        font-weight: 600;
    }

    p {
        font-size: ac(20px, 18px);
        line-height: 1.3;
        margin-bottom: 8px;

        a {
            font-size: inherit;
            margin-bottom: 0;
        }
    }

    a {
        font-size: 16px;
        display: inline-block;
        margin-bottom: 5px;
    }

    @media (max-width: 992px) {
        grid-template-columns: 1fr 1fr;
    }
    @media (max-width: 650px) {
        grid-template-columns: 1fr;
    }
}



.input-wr {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    span {
        padding-bottom: 11px;

        b{
            color: var(--red);
        }
    }

    input, textarea {
        width: 100%;
        padding: ac(19px, 15px) ac(30px, 10px);
        border: 1px solid var(--golden);
        border-radius: 31px;
        color: var(--tuna);
        font-size: ac(17px, 14px);
    }

    textarea{
        resize: none;
        height: 124px;
    }
}
.single-range-info {
    font-weight: 400;
    font-size: 17px;
    padding-bottom: 0;
}
.single-select-wr {
    display: flex;
    flex-direction: column;

    & > span {
        padding-bottom: 12px;

        b {
            color: var(--red);
        }
    }

    .choices {
        width: 100%;

        &__inner {
            padding: 0 ac(48px, 30px) 0 ac(30px, 15px);
            border: 1px solid var(--golden);
            border-radius: 31px;
            display: flex;
            min-height: ac(62px, 51px);
            align-items: center;
        }

        .choices__item--selectable {
            line-height: normal;
            color: var(--tuna);
        }

        &::before {
            content: url("../images/icons/chewron-down.svg");
            display: block;
            position: absolute;
            right: ac(30px, 15px);
            top: 50%;
            transform: translate(0, -50%);
            transform-origin: center center;
        }

        &.is-open {
            &::before {
                transform: rotateX(180deg) translateZ(-1px) translateY(50%);
            }
        }
    }
}

.single-range {
    padding-right: 10px;
    margin-bottom: 13px;
    height: 2px;
    border-radius: 12px;
    border: none;
    box-shadow: none;
    background: var(--blue);

    &-container {
        width: 100%;
        padding-top: 11px;
    }

    .noUi-connect {
        background: var(--blue);
    }

    .noUi-handle {
        background: var(--blue);
        width: 10px!important;
        height: 10px;
        cursor: pointer;
        border-radius: 100%;
        box-shadow: none;
        top: -4px;
        right: -10px!important;
        border: none;

        &:before,
        &:after {
            content: none;
        }
    }
}

.select-wr {
    .choices {
        border-bottom: 2px solid var(--blue);

        .choices__inner {
            min-height: auto;
        }

        .choices__list > div {
            height: auto;
            line-height: normal;
        }

        .choices__item--selectable {
            font-size: ac(30px, 25px);
            font-weight: bold;
            color: var(--tuna);
            opacity: 1;
            opacity: 0.5;
        }

        .choices__list>div{
            width: 100%;
            padding-right: 0;
        }

    }

    .choices[data-type*=select-one] .choices__input {
        display: none;
    }
}

@media (max-width: 1024px) {
    .ic-popup-container {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .ic-popup-form-grid {
        grid-template-columns: 1fr;
    }
}