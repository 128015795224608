.first {
	$gap: 0;
	padding-top: ac(155px, 120px, 1366, 1440);
	margin-top: $gap;

	.container {
		height: 100%;
	}

	&--home {
		padding-bottom: 82px;
		border-bottom: 1px solid var(--golden);

		.choices__list.choices__list--dropdown {
			min-width: 200px;
		}

		.first__wrapper {
			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: calc(100% - ac(200px, 100px, 550, 1440));
				background-color: var(--alice);
				border-radius: ac(231px, 50px);
				z-index: -1;
			}
		}
	}

	&--about {
		position: relative;
		z-index: 1;

		.first {
			&__head {
				position: relative;
				padding: ac(63px, 40px) 0 ac(60px, 40px);

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 50%;
					width: 100%;
					max-width: 1022px;
					height: calc(100% - ac(190px, 100px, 550, 1440));
					background-color: var(--alice);
					border-radius: ac(191px, 30px);
					transform: translateX(-50%);
				}
			}

			&__title {
				margin-top: 20px;
				width: 100%;
				max-width: ac(809px, 360px);
				text-align: center;

				span {
					color: var(--blue);
				}
			}

			&__img-box {
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: ac(0px, 20px);
				margin-right: ac(-15px, -5px);
            }

            &__img {
                width: ac(191px, 60px, 320, 1440);
                height: ac(191px, 60px, 320, 1440);
				margin-right: ac(15px, 5px);

				img {
					border-radius: 100%;
				}

				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					transform: translateY(17px);
				}
			}

            &__award {
                position: absolute;
                bottom: ac(148px, 50px);
				right: ac(-92px, -20px);
                height: ac(232px, 60px);
                width: ac(155px, 45px);
                z-index: 1;

                img {
                    object-fit: contain;
                }
            }

			&__text-box {
				position: relative;
				margin-top: ac(67px, 20px);
				max-width: ac(809px, 500px);

				&::before {
					content: '';
					position: absolute;
					top: ac(-94px, -40px, 500, 1440);
					right: ac(-685px, -80px, 500, 1440);
					width: ac(560px, 100px, 500, 1440);
					height: ac(560px, 100px, 500, 1440);
					border: ac(142px, 20px, 500, 1440) solid var(--golden);
					border-radius: 100%;
				}

				h3 {
					font-weight: 600;
					margin-top: ac(39px, 15px);
					font-size: ac(32px, 18px);
					line-height: ac(40px, 24px);
				}
			}

			&__descr {
				margin-top: ac(20px, 10px);
				font-weight: 600;
				font-size: ac(27px, 16px);
				line-height: ac(33px, 22px);
			}
		}
	}

	&--org,
	&--prof {

		.first {
			&__wrapper {
				position: relative;
				padding: ac(63px, 40px) 0 ac(60px, 40px);

				&::before {
					content: '';
					position: absolute;
					top: 0;
					left: 50%;
					width: 100%;
					max-width: 1022px;
					height: 100%;
					border-radius: ac(231px, 30px);
					transform: translateX(-50%);
				}
			}

			&__title {
				margin-top: 20px;
				padding: 0 10px;
				width: 100%;
				max-width: ac(809px, 360px);
				text-align: center;
			}

			&__img {
                position: absolute;
				width: ac(191px, 100px);
				height: ac(191px, 100px);

				img {
					border-radius: 100%;
				}
            }

			&__btn-box {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				padding: 0 10px;
				margin-top: ac(40px, 25px);
				margin-bottom: -15px;
				margin-right: -14px;
				z-index: 1;

				.btn {
					margin-bottom: 15px;
					margin-right: 14px;
				}
			}
		}
	}

	&--prof {
		padding-top: 155px;

		.first {
			&__wrapper {
				&::before {
					background-color: var(--blue);
				}
			}

			&__suptitle, &__title {
				color: var(--white);
			}

			&__title {
				span {
					color: var(--golden);
				}
			}

			&__suptitle {
				&::before {
					background-color: var(--white);
				}
			}

			&__img {
				&:nth-child(1) {
					right: 0;
					bottom: ac(-23px, -85px);
				}
				
				&:nth-child(2) {
					left: 0;
					top: ac(65px, -65px);
				}
			}
		}
	}

	&--org {
		padding-top: 155px;

		.first {
			&__wrapper {
				&::before {
					background-color: var(--golden);
				}
			}

			&__title {
				span {
					color: var(--blue);
				}
			}

			&__suptitle {
				color: var(--tuna);

				&::before {
					background-color: var(--tuna);
				}
			}

			&__img {
				&:nth-child(1) {
					left: 0;
					bottom: ac(17px, -85px, 600, 1440);
				}
				
				&:nth-child(2) {
					right: 0;
					top: ac(10px, -65px);
				}
			}
		}
	}

	&--contact {
        padding-top: ac(216px, 146px);
        padding-bottom: 40px !important;
        height: auto !important;
        border-bottom: none;

        .first {
            &__wrapper {
                justify-content: space-between;
				align-items: flex-start;
				flex-direction: row;
            }

            &__suptitle {
                display: inline-block;
                margin-bottom: ac(23px, 15px);
            }

            &__title {
                margin-bottom: ac(40px, 25px);

                span {
                    color: var(--blue);
                }
            }

            &__caption {
                max-width: 603px;
            }

            &__motto {
                p {
                    font-size: ac(21px, 16px);
                    line-height: ac(26px, 20px);

                    &:first-of-type {
                        font-size: ac(27px, 18px);
                        line-height: normal;
                        font-weight: 600;
                        margin-bottom: ac(20px, 15px);
                    }
                }
            }

            &__contact-info {
                display: flex;
                flex-direction: column;
				align-items: flex-start;
				margin-top: 18px;
				padding: 60px 77px 67px 100px;
				z-index: 1;

				background: var(--alice);
				border-radius: 99px;

				a {
					&:not(:first-child) {
						margin-top: ac(25px, 15px);
					}
					font-size: ac(31px, 18px);
					font-weight: 700;

					&:hover {
						color: var(--blue);
					}
				}
            }
        }
    }

	&--member {
		position: relative;
		padding-top: ac(178px, 140px);
		min-height: 645px;

		.first {
			&__wrapper {
				align-items: flex-start;
			}

			&__heading {
				max-width: 480px;
			}

			&__title {
				margin-bottom: ac(35px, 15px);
			}

			&__subtitle {
				font-weight: 600;
				font-size: 21px;
				line-height: 22px;
				display: inline-block;
				margin-bottom: 17px;
				color: var(--blue);
				padding-bottom: 3px;
				border-bottom: 2px solid var(--blue);
			}

			&__descr {
				font-weight: 600;
				font-size: ac(27px, 20px);
				line-height: ac(33px, 26px);
			}

			&__contacts {
				margin-bottom: ac(45px, 20px);
	
			}

			&__contacts-link {
				display: block;
				width: fit-content;
				font-weight: 600;
				font-size: ac(27px, 18px);

				&:not(:first-child) {
					margin-top: ac(20px, 10px);
				}

				&:hover {
					color: var(--blue);
				}
			}

			&__img {
				width: vw(712);
				height: vw(428);
				max-width: ac(712px, 420px, 1024, 1440);
				max-height: 428px;
				min-height: ac(375px, 300px);
	
				margin: 0;
				right: 0;
				left: unset;
				top: unset;
				bottom: 36px;
				position: absolute;
				border-radius: ac(214px, 30px);
				overflow: hidden;
				background-color: transparent !important;
			}

			&__socials {
				bottom: 40px;
				right: ac(108px, 40px);
				position: absolute;
			}

			&__socials-link {
				width: ac(88px, 70px);
				height: ac(88px, 70px);
				background: var(--golden);
				font-size: ac(50px, 36px);
				border-radius: 100%;

				display: flex;
				align-items: center;
				justify-content: center;

				&:hover {
					background: var(--blue);
				}
			}
		}

	}

	&--details {
        padding-top: ac(218px, 150px);
		overflow: hidden;

        .first {
            &__wrapper {
				align-items: flex-start;

                .btn {
                    margin-top: ac(48px, 25px);
                }
            }

            &__title {
                margin-top: ac(20px, 15px);
            }

			&__bottom {
                margin-top: ac(41px, 30px);
				width: 100%;
			}

            &__info-box {
                display: flex;
                flex-wrap: wrap;
                margin-right: ac(-155px, -20px);
                margin-bottom: -15px;
            }

            &__info {
                display: flex;
                flex-direction: column;
                margin-right: ac(158px, 20px);
                margin-bottom: 15px;

				&:nth-child(2) {
					margin-right: ac(181px, 20px);
				}

                span {
                    &:nth-child(1) {
                        line-height: 19px;
                        color: var(--blue);
                    }

                    &:nth-child(2) {
                        margin-top: 5px;
                        font-weight: 600;
                        font-size: ac(27px, 18px);
                        line-height: ac(36px, 24px);
                    }
                }
            }
        }
    }

	&--article {
        padding-top: ac(144px, 150px);

        .first {
            &__title {
				position: relative;
				max-width: 1023px;
                color: var(--blue);
				background-color: var(--alice);
				border-radius: ac(150px, 30px);
				margin-left: ac(-110px, 0px, 1024, 1440);
				padding: ac(60px, 25px) ac(110px, 15px);
                z-index: 1;
            }

            &__wrapper {
                margin-top: 90px;
                align-items: flex-start;
            }

            &__col {
				margin-top: ac(33px, 20px);
                flex: 0 0 auto;
                width: 100%;
                max-width: 809px;
                margin-right: 118px;
                min-height: unset;
            }

            &__descr {
                margin-top: ac(98px, 40px);
                font-weight: 600;
                font-size: ac(32px, 22px);
                line-height: ac(39px, 30px);
            }

            &__info {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                margin-right: ac(-38px, -15px);
                margin-bottom: -10px;

                span {
					font-size: ac(17px, 14px, 320, 1440);
                    line-height: 19px;
                    margin-right: ac(42px, 15px);
                    margin-bottom: 10px;

					&:nth-child(2) {
						margin-right: ac(30px, 15px);
					}

                    &:first-child {
                        padding: 6px 11px;
                        border-radius: 15px;
                        background-color: var(--golden);
                    }
                }
            }

            &__img-box {
                position: relative;
				bottom: unset;
				right: unset;
                margin-top: ac(-254px, -140px);
                width: 100%;
                height: ac(452px, 220px, 320, 1440);
            }

            &__img {
				left: 0;
				top: 0;
                width: 100%;
                height: 100%;
				background-color: transparent !important;

                img {
                    border-radius: ac(226px, 30px);
                }
            }
        }
    }

	&--blogs,
    &--category {
        padding-top: ac(217px, 150px);

        .first {
			&__wrapper {
				align-items: flex-start;
				position: relative;
            }

			&__icon-box {
				position: absolute;
				right: 0;
				bottom: 179px;
				width: ac(499px, 250px, 768, 1440);
				height: ac(499px, 250px, 768, 1440);
				padding: 25px ac(102px, 35px, 768, 1440);
				display: flex;
				flex-direction: column;
				justify-content: center;
				background-color: var(--alice);
				border-radius: 100%;
				z-index: 1;
				transition: .3s ease;

				p {
					font-weight: 700;
					font-family: var(--font-sec);
					font-size: ac(25px, 16px, 768, 1440);
					line-height: ac(30px, 22px, 768, 1440);
					letter-spacing: .1px;
					max-height: ac(120px, 88px, 768, 1440);
					transition: .3s ease;
					@mixin max-line-length 4;
				}

				&:hover {
					background-color: var(--blue);

					p {
						color: var(--white)
					}

					.first__btn {
						span:nth-child(1) {
							color: var(--white);
						}

						.arrow-btn {
							background-color: var(--white);

							.icon-arrow {
								color: var(--blue);
							}
						}
					}
				}
			}

            &__title {
				max-width: ac(706px, 300px);
                color: var(--blue);
            }

            &__descr {
				margin-top: ac(27px, 15px);
				max-width: 604px;
                font-weight: 600;
                font-size: ac(32px, 22px);
                line-height: ac(39px, 30px);
            }

            &__icon {
				position: absolute;
				left: ac(102px, 40px, 768, 1440);
				top: ac(-82px, -20px, 768, 1440);
                width: ac(191px, 82px, 768, 1440);
                height: ac(174px, 68px, 768, 1440);
				pointer-events: none;

				img {
                    object-fit: contain;
                }
            }

            &__img {
				margin-top: ac(61px, 180px);
				width: 100%;
				height: ac(452px, 280px);

                img {
                    border-radius: ac(226px, 30px);
                }
            }

            &__btn {
                display: flex;
				align-items: center;
				margin-bottom: ac(20px, 10px, 768, 1440);
				pointer-events: none;

                & > span {
					transition: .3s ease;

                    &:nth-child(1) {
                        margin-right: ac(48px, 20px, 768, 1440);
                        font-weight: 700;
                        font-size: ac(53px, 22px, 768, 1440);
                        line-height: ac(63px, 28px, 768, 1440);
                    }
                }

                .arrow-btn {
					width: ac(48px, 28px, 768, 1440);
					height: ac(48px, 28px, 768, 1440);

					.icon-arrow {
						font-size: ac(21px, 14px, 768, 1440);
						line-height: ac(48px, 28px, 768, 1440);
                	}
                }
            }
            
          
        }
    }

	&--category {
		.first {
			&__descr {
				margin-top: ac(37px, 15px);
            }

			&__img {
				margin-top: ac(48px, 180px);
            }
		}
	}

	&--resources {
        padding-top: ac(217px, 150px);
        padding-bottom: 0;
        border-bottom: 0;
        height: auto;

        .first {
            &__grid {
                margin-top: ac(94px, 35px);

                &>h2 {
                    white-space: nowrap;
                }
            }

            &__wrapper {
				align-items: flex-start;
            }

            &__title {
				max-width: 1015px;
                margin-top: 21px;

                span {
                    color: var(--blue);
                }
            }

            &__submit {
                right: ac(-38px, 0px, 1024, 1440);
                top: -12px;
				transform: rotate(15deg);

				span {
					transform: rotate(30deg);
				}
            }

            &__form {
                margin-top: ac(64px, 35px);
                border: 0;
                max-width: ac(980px, 860px, 1024, 1440);
                margin-left: 0;
                padding: 0;
            }

            &__select-box {
                display: flex;
                font-weight: 700;
                font-size: ac(31px, 20px);
                line-height: ac(30px, 26px);

                span {
                    flex: 0 0 auto;
                }

                .choices {
                    margin-left: 10px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        width: ac(281px, 174px, 650, 1440);
						margin-left: 26px;
                    }

                    &__inner {
                        padding-left: 0;
                    }

                    .choices__list--single {
                        .choices__item {
                            font-weight: 700;
                            font-size: ac(31px, 20px);
                			line-height: ac(30px, 26px);
                            border-bottom: 2px solid var(--blue);
                            max-width: 300px;
                            max-height: ac(40px, 32px);
                            padding: 0 0 3px !important;
                        }

                        .choices__placeholder {
                            color: rgba(43, 50, 69, 0.5);
                        }
                    }

                    .choices__list--dropdown {
                        .choices__item {
                            padding: 5px 10px;
                            font-weight: 400;
                            font-size: ac(18px, 15px, 1024, 1440);
                            line-height: ac(32px, 28px);

                            &::after {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }

	&--positions {
        padding-top: ac(215px, 150px);
        padding-bottom: 0;
        border-bottom: 0;

        .first {
            &__wrapper {
                position: relative;
				align-items: flex-start;
            }

            &__decor {
                position: absolute;
                right: 0;
                top: ac(-98px, -60px, 320, 1440);
                width: ac(294px, 100px, 320, 1440);
                height: ac(294px, 100px, 320, 1440);
                z-index: -1;

                img {
                    object-fit: contain;
                }
            }

            &__title {
                margin-top: 21px;
				max-width: ac(1015px, 300px);
            }

            &__form {
                margin-top: 30px;
                margin-bottom: 14px;

                &--hidden {
                    margin-top: 0;
                    margin-bottom: 0;

					.choices {
                        min-width: ac(313px, 215px, 768, 1440);
                    }
                }
            }

            &__tags {
                margin-top: 22px;

				span {
					margin-right: 18px;
				}
            }

			&__tag {
				margin-right: 18px;
			}
        }
    }

	&--careers {
        padding-top: ac(215px, 150px);
        padding-bottom: 40px;
        border-bottom: 1px solid var(--golden);

        .first {
            &__wrapper {
                position: relative;
				align-items: flex-start;
				flex-direction: row;

                .first__btn {
                    position: absolute;
                    right: 0;
                    top: ac(-37px, -15px);
					width: 100%;
					max-width: ac(500px, 200px);
					padding: ac(30px, 15px);
					flex-direction: row;
                    align-items: center;
					justify-content: center;
					background-color: var(--tuna);
					border-radius: ac(54px, 30px);
                    z-index: 1;

					.arrow-btn {
						margin-right: ac(21px, 10px);
						margin-bottom: 0;
					}

                    span:not(.arrow-btn) {
                        font-weight: 700;
                        font-size: ac(27px, 16px);
                        line-height: ac(34px, 20px);
                        color: var(--white);
                        transition: 0.3s ease;
                    }
                }

                & > .first__btn {
                    .arrow-btn {
                        width: ac(48px, 29px);
                        height: ac(48px, 29px);

						.icon-arrow {
							font-size: ac(22px, 16px);
						}
                    }
                }
            }

            &__col {
                max-width: ac(707px, 550px, 1024, 1440);
                align-items: flex-start;
            }

            &__title {
                margin-top: ac(21px, 40px);

                span {
                    color: var(--blue);
                }
            }

            &__descr {
                margin-top: ac(42px, 25px);
                max-width: 499px;

                p {
                    &:first-child {
						font-size: ac(21px, 18px);
                        line-height: 26px;
                    }

                    &:not(:first-child) {
                        margin-top: ac(19px, 15px);
                    }
                }
            }

            &__img-box {
                position: relative;
                margin-top: 121px;
				margin-left: ac(0px, 68px, 1024, 1440);
                display: flex;
                flex-direction: column;
                row-gap: 15px;
            }

            &__award {
                position: absolute;
                bottom: ac(-2px, 30px, 1024, 1440);
                left: ac(365px, 240px, 1024, 1440);
                height: ac(240px, 160px, 1024, 1440);
                width: ac(154px, 110px, 1024, 1440);
                z-index: 1;

                img {
                    object-fit: contain;
                }
            }

            &__img-row {
                display: flex;
                gap: 15px;
				margin-left: -88px;

                &:first-child {
                    margin-left: 15px;
                }
            }

            &__img {
                position: relative;
                width: ac(191px, 150px, 1024, 1440);
                height: ac(191px, 150px, 1024, 1440);
                border-radius: 100%;
                overflow: hidden;
            }

            &__btn-box {
                margin-top: ac(33px, 25px);
                display: flex;
                flex-wrap: wrap;
                gap: ac(94px, 15px);
            }

            &__btn {
                display: flex;
				flex-direction: column;
				align-items: flex-start;
               
                min-width: 216px;
                margin-right: 0;
                background-color: transparent;

				.arrow-btn {
					margin-bottom: ac(15px, 10px);
                    justify-content: center;
                    align-items: center;
					background-color: var(--golden);

                    .icon-arrow {
                        transform: rotate(90deg);
						font-size: 22px;
                    }
                }

                span:not(.arrow-btn) {
                    font-weight: 700;
                    font-size: 27px;
                    line-height: 30px;
                    transition: 0.3s ease;
                }

                &:hover {
                    .arrow-btn {
                        background-color: var(--blue);
                    }

                    span:not(.arrow-btn) {
                        color: var(--blue);
                    }
                }

                
            }
        }
    }

	&--calculator {
		padding-top: ac(218px, 150px);

		.first {
			&__suptitle {
				display: inline-block;
				margin-bottom: 21px;
			}

			&__title {
				margin-bottom: ac(40px, 25px);

				span {
					color: var(--blue);
				}
			}

			&__motto {
				font-size: ac(32px, 20px);
				font-weight: 600;
				line-height: 1.2;
				max-width: 809px;

			}

			&__wrapper {
				align-items: flex-start;
			}

			&__thumbnail {
				position: absolute;
                right: 0;
                top: ac(-18px, -60px, 768, 1440);
                width: ac(294px, 100px, 768, 1440);
                height: ac(294px, 100px, 768, 1440);
                z-index: -1;

                img {
                    object-fit: contain;
                }
			}
		}
	}

	&__tag-box {
		display: flex;
		align-items: flex-start;
		max-width: 809px;

		.first__tags {
			margin-right: 20px;
			margin-top: 0px;
		}
	}

	&__keys {
		margin-top: ac(49px, 35px);
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		margin-bottom: -10px;
	}

	&__key {
		margin-right: 16px;
		margin-bottom: 10px;
		line-height: 19px;
		padding: 10px 15px;
		background-color: rgba(5, 142, 217, .5);
		border-radius: 7px;
		cursor: pointer;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: var(--blue);
		}
	}

	&__suptitle {
		position: relative;
		color: var(--blue);
		font-weight: 700;
		font-size: ac(21px, 16px);

		&::before {
			content: "";
			position: absolute;
			bottom: -6px;
			left: 0;
			width: 100%;
			height: 2px;
			background-color: var(--blue);
		}
	}

	&__wrapper {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__title-box {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		z-index: 2;
		padding: ac(77px, 35px) ac(242px, 25px, 768, 1440);
	}

	&__title {
		& > span {
			position: relative;

			& > span {
				position: absolute;
				bottom: ac(-12px, -8px);
				left: 0;
				width: 100%;
				height: ac(13px, 8px);
				background-color: var(--blue);
				z-index: -1;
				border-radius: 7px;
			}
		}
	}

	&__subtitle {
		margin-top: ac(40px, 25px, 1024, 1440);
		font-size: ac(32px, 22px);
		line-height: ac(42px, 30px);
		font-weight: 600;
		max-width: ac(580px, 500px);
	}

	&__submit {
		position: absolute;
		right: 6px;
		top: 6px;
		width: 48px;
		height: 48px;

		&.static {
			position: static;
		}

		span {
			font-size: 21px;
			line-height: 48px;
		}
	}

	&__tags {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		margin: ac(14px, 20px) ac(-15px, -21px) -11px 2px;

		span {
			opacity: 0.5;
		}

		span,
		a {
			font-size: ac(17px, 14px);
			margin-right: ac(15px, 21px);
			margin-bottom: 11px;
		}

		a {
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: ac(-1px, -2px);
				width: 100%;
				height: 2px;
				background-color: var(--blue);
			}

			&:hover {
				color: var(--blue);
			}
		}
	}

	&__circle-box {
		display: flex;
		margin-top: -24px;
		margin-right: ac(-61px, -15px);
	}

	&__circle {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		width: ac(224px, 150px, 768, 1440);
		height: ac(224px, 150px, 768, 1440);
		border-radius: 100%;
		margin-right: ac(61px, 15px, 768, 1440);

		.first__btn {
			position: absolute;
			bottom: ac(10px, 0px);
			left: 50%;
			background-color: var(--golden);
			transform: translate(-50%, 50%);

			.icon-arrow {
				font-size: 21px;
				color: var(--tuna);
			}
		}

		.first__img {
			position: absolute;
			width: 100%;
			height: 100%;
			border-radius: 100%;
			overflow: hidden;
			z-index: -1;

			img {
				transition: .3s ease;
				z-index: -1;
				position: relative;
			}

			&::before {
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(0,0,0, .3);
			}
		}

		&:hover {
			.first__btn {
				background-color: var(--blue);
	
				.icon-arrow {
					color: var(--white);
				}
			}

			.first__img {
				img {
					transform: scale(1.1);
				}
			}
		}
	}

	&__circle-title {
		text-align: center;
		color: var(--white);
		font-size: ac(37px, 24px, 768, 1440);
		line-height: ac(44px, 30px, 768, 1440);
		font-weight: 700;
		transition: .3s ease;
	}

	&__form {
        position: relative;
        display: flex;
        margin-top: 20px;
        margin-left: 2px;
        padding: 6px 62px 6px 7px;
        width: 100%;
        max-width: 705px;
        background-color: var(--white);
        border-radius: 31px;
        border: 1px solid var(--golden);

        .choices__inner {
            padding-left: 30px;
        }

        input {
			border-top-left-radius: ac(31px, 10px, 551, 552);
			border-bottom-left-radius: ac(31px, 0px, 551, 552);
            width: 100%;
            max-width: ac(370px, 245px, 1024, 1440);
            padding-left: 23px;
            padding-right: 30px;
            border-right: 1px solid var(--tuna);

            &,
            &::placeholder {
                line-height: 48px;
            }
        }

        &--hidden {
            margin-top: 0;
            margin-bottom: 10px;
            max-width: 959px;
            padding: 6px 7px;

            .choices {
				flex-basis: 100%;

				&:last-child {
					.choices__inner {
						border-radius: 0 30px 30px 0;
					}
				}

				&:first-child {
					.choices__inner {
						border-radius:  30px 0 0 30px;
					}
				}

                &::before {
                    content: '';
                    display: block;
                    position: absolute;
                    right: ac(32px, 5px);
                    top: ac(19px, 22px);
                    width: 8px;
                    height: 8px;
                    border-right: 2px solid var(--tuna);
                    border-bottom: 2px solid var(--tuna);
                    transform: rotate(45deg);
                    transition: .3s ease;
                }

                &:not(:first-child) {
                    .choices__inner {
                        padding-left: ac(29px, 15px);
                    }
                }

                &:nth-child(2) {
                    min-width: ac(320px, 225px, 768, 1440);
                }

                &:not(:last-of-type) {
                    border-right: 1px solid var(--tuna);
                }

                &__inner {
                    padding-left: ac(22px, 15px);
                }

                &.is-open {
                    &::before {
                        transform: rotate(-130deg);
                        top: ac(22px, 28px);
                    }
                }
            }

            @mixin tab-sm {
                flex-direction: column;
                padding: 0 20px;

                .choices {
                    .choices__inner {
                        padding-left: 0;
                    }

					&:last-of-type {
						.choices__inner {
							border-radius: 0 0 30px 30px;
							
						}
					}
	
					&:first-child {
						.choices__inner {
							border-radius: 30px 30px 0 0;
						}
					}

                    &:not(:first-child) {
                        .choices__inner {
                            padding-left: 0;
                        }
                    }

                    &:nth-child(2) {
                        min-width: ac(320px, 225px, 768, 1440);
                    }

                    &:not(:last-of-type) {
                        border-right: 0;
                        border-bottom: 1px solid var(--tuna);
                    }
                }
            }
        }
    }

    &__accardion-head {
        position: relative;
        display: inline-flex;
        padding-right: 22px;
        padding-left: 2px;
        width: 151px;
        transition: .3s ease;
        cursor: pointer;
        transform: translateY(-2px);

        span {
			transition: .3s ease;
			
            &:first-child, &:nth-child(2) {
                font-weight: 700;
                font-size: 18px;
                line-height: 19px;
                position: relative;
				letter-spacing: -0.5px;

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    bottom: -5px;
                    background-color: var(--tuna);
                    transition: .3s ease;
                }
            }

            &:nth-child(2) {
                position: absolute;
                opacity: 0;
                width: 170px;
            }

            &.icon-arrow {
                position: absolute;
                color: var(--blue);
				right: -5px;
				top: 2px;
                font-size: 14px;
                transform: rotate(90deg);
                transition: .3s ease;
            }
        }

        &:hover {
            span {
                &:first-child, &:nth-child(2) {
                    color: var(--blue);

                    &::before {
                        background-color: var(--blue);
                    }
                }
            }
        }

        &.open {
            width: 210px;
            transform: translateY(1px);
            margin-bottom: 5px;

            span {
                &:first-child {
                    opacity: 0;
                }

                &:nth-child(2) {
                    opacity: 1;
                    transition: .3s ease;
                }

                &.icon-arrow {
					right: 10px;
                    transform: rotate(0deg);
                }
            }
        }
    }

    &__accardion-content {
        opacity: 0;
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: 0.5s ease;

        &.open {
            opacity: 1;
            overflow: visible;
            max-height: 1000px;
            margin-bottom: 13px;
        }
    }

	&__grid-head {
        display: flex;
        justify-content: space-between;
        border: 1px solid var(--alice);
        background-color: var(--alice);
        border-radius: 40px;
        font-weight: 600;
        font-size: ac(27px, 18px);
        line-height: ac(33px, 22px);
        padding: ac(30px, 20px, 651, 1440) ac(30px, 20px, 651, 1440);
        transition: 0.3s ease !important;
        cursor: pointer;

        p {
            margin-right: ac(30px, 15px);
        }

        .first__btn {
            margin-right: 0;
            width: 29px;
            height: 29px;
            margin-bottom: auto;
			background-color: var(--golden);

            span {
                font-size: 15px;
                line-height: 29px;
				color: var(--tuna);
            }
        }

        &:hover,
        &.open {
            background-color: var(--tuna) !important;
            color: var(--white) !important;

			.first__btn {
				background-color: var(--blue);

				span {
					transform: rotate(90deg);
				}
			}
        }
    }

    &__grid-content {
        max-height: 0;
        overflow: hidden;

        background-color: var(--alice);
        transition: 0.5s ease;
        opacity: 0;
		border-radius: 30px;
		
        div {
            padding: ac(30px, 15px);
            border: 1px solid var(--alice);
        }

        &.open {
            opacity: 1;
            max-height: 2000px;
        }
    }


	@media (min-width: 770px) {
        &__grid {
            display: grid;
            gap: 15px;
            grid-template-areas:
				"item item item"
				"desc desc desc";
            grid-template-columns: 1fr 1fr 1fr;
        }

        &__grid-content {
            grid-area: desc;
        }
    }

	@media (min-width: 1920px) {
		&--member {
			.container {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
	
			.first__img {
				position: relative;
			}
		}
		
	}

	@mixin tab {
		&--contact {
			padding-top: ac(216px, 146px);
			padding-bottom: 40px !important;
			height: auto !important;
			border-bottom: none;
	
			.first__contact-info {
				margin-top: 20px;
				padding: ac(30px, 20px) ac(45px, 25px);
			}
		}

		&--member {
			padding-bottom: ac(380px, 340px, 375, 1024);

			.first__img {
				max-width: 600px;
				width: 100%;
				bottom: 0;
			}
		}

		&--resources {
            padding-bottom: 0;

            .first__select-box {
                flex-wrap: wrap;

                .choices .choices__list--single .choices__item {
                    max-width: unset;
                }
            }

            .first__form {
                padding-right: ac(100px, 60px, 650, 1024);
            }
        }

		&--careers {
			.first__wrapper {
				flex-direction: column;
			}

			.first__img-box {
				position: relative;
				bottom: unset;
				right: unset;
				margin: 20px auto 0;
			}

			.first__descr, .first__col  {
				max-width: unset;
			}
		}	

		&--calculator {
			.first__wrapper {
				flex-direction: column-reverse;
			}
		}

		&__wrapper {
			flex-direction: column;
		}

		&__tag-box {
			flex-direction: column-reverse;

			.first__tags {
				margin-top: 20px;
				margin-right: 0;
			}
		}
	}


	@mixin tab-sm {
        &__grid-head {
			align-items: center;
			margin-bottom: 15px;
        }

		&__grid-content.open {
			margin-bottom: 15px;
		}

		&__grid {
			&:last-child {
				.first__grid-head {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}

        &--resources {
            .first__select-box {
                .choices {
                    margin-left: 9px;
                    margin-right: 9px;

                    &:nth-of-type(1) {
                        min-width: ac(174px, 134px, 320, 1440);
                    }

                    &:nth-of-type(2) {
                        margin: 5px 0 0;
                        width: 100%;
                    }
                }
            }
        }

		&--contact {
			.first__wrapper {
				flex-direction: column;
			}
		}
    }

	@mixin mob-lg {
		&--about {
			.first__img-box {
				flex-wrap: wrap;
			}

			.first__img {
				width: ac(120px, 80px, 320, 551);
				height: ac(120px, 80px, 320, 551);

				&:nth-child(2), &:nth-child(3), &:nth-child(4) {
					transform: translateY(0px);
				}
			}

			.first__award {
				bottom: 20px;
				right: ac(20px, 5px, 320, 551);
				width: ac(80px, 60px, 320, 551);
				height: ac(120px, 80px, 320, 551);
			}
		}

		&--member {
			.first__img {
				max-width: 90%;
			}
		}

		&--careers {
			.first__img-row {
				margin-left: -30px;
			}

			.first__img {
				width: 100px;
				height: 100px;
			}

			.first__award {
				height: 120px;
				width: 85px;
				bottom: 20px;
				left: 175px;
			}
		}

		&__form {
			padding: 0 80px 0 20px;
			margin-top: 20px;
			margin-left: 0;
			flex-direction: column;

			input {
				padding-right: 0;
				border-right: 0;
				border-bottom: 1px solid var(--tuna);
			}

			.choices__inner {
				border-bottom-left-radius: 10px;
			}

			.choices__inner,
			input {
				padding-left: 0;
			}

			.choices__item--selectable,
			input {
				line-height: 52px;
				max-width: 100%;
			}
		}

		&__form--hidden {
			padding-right: 20px;
		}

		&__submit {
			right: 10px;
			top: 28px;
		}

		&__tags {
			margin-left: 0;
		}

		&__circle-box {
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 0;
		}

		&__circle {
			margin-bottom: 40px;
		}
	}
}
	