.header {
	position: fixed;
	width: 100%;
	transition: 0.3s ease;
	z-index: 50;

	&.sticky {
		background-color: var(--white);
		border-bottom: 1px solid var(--tuna);

		.header__logo {
			transform: translateY(0px);
		}
	}

	&__wrap,
	&__top .container {
		display: flex;
	}

	&__logo img,
	&__layout {
		position: absolute;
	}

	&__top {
		padding: 13px 0;
		background-color: var(--golden);

		.header__link {
			font-size: 15px;
			margin-left: 31px;

			&:first-child {
				margin-left: auto;
			}

			&::before {
				display: none;
			}
		}
	}

	&__wrap {
		align-items: center;
		padding: 24px 0;
		transition: 0.3s ease;
	}

	&__logo {
		flex: 0 0 auto;
		position: relative;
		width: 141px;
		height: 36px;
		z-index: 1;
		transform: translateY(-3px);

		img {
			object-fit: contain;
			transition: 0.3s ease;

			&:nth-child(2) {
				opacity: 0;
			}
		}

		&:hover {
			img {
				&:nth-child(1) {
					opacity: 0;
				}

				&:nth-child(2) {
					opacity: 1;
				}
			}
		}
	}

	&__navbar {
		display: flex;
	}

	&__link {
		position: relative;
		margin-left: ac(27px, 12px, 1024, 1440);
		font-size: ac(17px, 14px, 1024, 1440);
		line-height: 1em;
		font-weight: 600;
		cursor: pointer;

		&:first-child {
			margin-left: auto;
		}

		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -7px;
			width: 0;
			height: 2px;
			background-color: var(--tuna);
			transition: 0.3s ease;
		}

		&.active,
		&:hover {
			color: var(--blue);

			&::before {
				width: 100%;
				left: unset;
				right: 0;
			}
		}
	}

	&__layout {
		width: 100%;
		height: 100vh;
		bottom: -1px;
		transform: translateY(100%);
		background-color: var(--white);
		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: -1;
		cursor: pointer;
	}

	&__icon {
		flex: 0 0 auto;
		width: 40px;
		height: 37px;
		margin-right: 58px;

		img {
			object-fit: contain;
		}
	}

	.js-dropdown {
		position: relative;
		margin-left: ac(26px, 12px, 1024, 1440);
		z-index: 2;

		.header__link--btn {
			cursor: pointer;
			transition: .3s ease;
			width: 100%;

			&::before {
				display: none;
			}

			span {
				position: absolute;
				top: 5px;
				right: ac(-15px, -10px, 1024, 1440);
				width: 10px;
				height: 6px;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath stroke='%23080223' stroke-linecap='round' stroke-width='2' d='m1 1 5 5 5-5'/%3E%3C/svg%3E");
				transition: .3s ease;
			}

			&:hover {
				span {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='8' fill='none'%3E%3Cpath stroke='%232D756E' stroke-linecap='round' stroke-width='2' d='m1 1 5 5 5-5'/%3E%3C/svg%3E");
				}
			}
		}
	}

	&__dropdown {
		width: calc(100% + ac(40px, 15px, 1024, 1440));
		position: absolute;
		right: 50%;
		bottom: -8px;
		transform: translate(50%, 100%);
		transform-origin: 0 0;
		border: 1px solid var(--blue);
		overflow: hidden;
		visibility: hidden;
		background-color: var(--white);
		transition: .5s ease-in-out;
		max-height: 0px;
		border-radius: 7px;

		.header__link {
			margin-left: 0;
			width: 100%;
			&:not(:first-child) {
				margin-top: 5px;
			}
			&:before {
				display: none;
			}
		}

		&.open {
			max-height: 200px;
			opacity: 1;
			visibility: visible;
			overflow: hidden;
		}

		& > div {
			padding: 10px;
			max-height: 200px;
			overflow: auto;
			width: 100%;

			.simplebar-track.simplebar-vertical {
				width: 6px;
			}

			.simplebar-scrollbar {

				&::before {
					opacity: 1;
					background: var(--blue);
				}
			}
		}
	}

	@media (min-width: 1024px) {
		&.sticky {
			margin-top: -40px;

			.header__wrap {
				padding: 10px 0;
			}

			&:hover {
				margin-top: 0;
			}
		}

		&__simplebar {
			max-height: 21px;

			&>.simplebar-wrapper>.simplebar-mask {
				&, &>.simplebar-offset>.simplebar-content-wrapper {
					overflow: visible !important;
				}

				&>.simplebar-offset>.simplebar-content-wrapper>.simplebar-content {
					display: flex;
					align-items: center;
					justify-content: flex-end;
				}
			}
		
			&>.simplebar-track {
				display: none !important;
			}
		}
		
		&__navbar-box {
			margin-left: auto;
			width: calc(100% - 150px);
		}

		&__dropdown {
			.simplebar-content-wrapper, .simplebar-mask {
				overflow: auto !important;
			}

			.simplebar-content {
				flex-direction: column;
			}

			.simplebar-track {
				display: block !important;
			}
		}
	}

	@mixin tab {
		padding: 30px 0;

		&.sticky {
			padding: 10px 0;
		}

		&.active {
			.header__navbar-box {
				transform: translateX(0%);
				opacity: 1;
				visibility: visible;
			}

			.header__layout {
				opacity: 0.3;
				visibility: visible;
			}

			.header__burger {
				span {
					&:nth-child(1) {
						width: 0;
						opacity: 0;
					}

					&:nth-child(2) {
						transform: rotate(45deg);
					}

					&:nth-child(3) {
						width: 38px;
						transform: rotate(-45deg) translate(7px, -7px);
					}
				}
			}
		}

		.header__simplebar {
			max-height: 100%;
			padding-right: ac(20px, 10px, 374, 375);
			width: calc(100% + ac(20px, 10px, 374, 375));
		}

		.simplebar-track {
			width: 6px;
			background-color: rgb(8, 2, 35, .2);
			border-radius: 0;
		}

		.simplebar-content-wrapper {
			padding: 0 ac(30px, 15px) 0 0;
		}

		.simplebar-scrollbar::before {
			background-color: var(--tuna);
			border-radius: 0;
		}

		&__wrap {
			padding: 0;
		}

		&__burger {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-end;
			margin-left: auto;
			width: 46px;
			height: 24px;
			cursor: pointer;
			z-index: 1;

			span {
				width: 100%;
				height: 4px;
				background-color: var(--tuna);
				transition: 0.3s ease;

				&:nth-child(1) {
					width: 29px;
				}

				&:nth-child(2) {
					width: 38px;
				}
			}

			&:hover {
				span {
					background-color: var(--blue);
				}
			}
		}

		&__navbar-box {
			width: 100%;
			max-width: 400px;
			height: 100vh;
			height: calc(var(--vh, 1vh) * 100);
			position: absolute;
			right: 0;
			top: 0;
			padding: 137px 38px 27px;
			background-color: var(--alice);
			transform: translateX(100%);
			opacity: 0;
			visibility: hidden;
			transition: 0.3s ease;
		}

		&__navbar {
			width: 100%;
			flex-direction: column;
		}

		&__link {
			font-size: 28px;
			line-height: 34px;
			margin-left: 0;

			&:first-child {
				margin-left: 0;
			}

			&:not(:first-child) {
				margin-top: 20px;
			}

			&::before {
				display: none;
			}
		}

		&__bottom {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			padding-left: 2px;
			margin-top: 87px;

			.header__link {
				font-size: 20px;
				line-height: 24px;

				&:not(:first-child) {
					margin-top: 15px;
				}
			}
		}

		.js-dropdown {
			margin-top: 20px;
			margin-left: 0;

			.header__link--btn {
				span {
					top: 5px;
					right: 0;
					width: 32px;
					height: 28px;
					background-size: 12px 8px;
				}
			}
		}

		&__dropdown {
			position: relative;
			transform: none;
			width: 100%;
			left: 0;
			bottom: 2px;
			background: var(--white);

			.header__link {
				font-size: 22px;
				line-height: 30px;
			}

			&.open {
				margin-top: 10px;
			}
		}
	}

	@mixin mob-xl {
		&__navbar-box {
			max-width: unset;
		}
	}

	@mixin mob-sm {
		&__navbar-box {
			padding: 137px 15px 27px;
		}
	}
}
