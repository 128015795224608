.ways {
    $gap: ac(178px, 75px);
    margin-top: $gap;
    position: relative;

    &__wrapper {
        margin-top: ac(39px, 25px);
        display: flex;
        flex-wrap: wrap;
        gap: ac(30px, 15px) 15px;
        margin-right: -15px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 3 - 15px);
    }

    &__icon {
        width: ac(80px, 60px);
        height: ac(38px, 30px);

        img {
            object-fit: contain;
            object-position: left bottom;
        }
    }

    &__title {
        margin-top: ac(15px, 10px);
        position: relative;
        font-weight: 600;
        font-size: ac(27px, 18px);
        line-height: ac(35px, 22px);

        &::before {
            content: '';
            position: absolute;
            bottom: ac(-10px, -5px);
            left: 0;
            width: 90%;
            max-width: 191px;
            height: 1px;
            background-color: var(--golden);
        }
    }

    &__descr {
        margin-top: ac(19px, 15px);
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }
    }
    
    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}