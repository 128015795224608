.meet-team {
    $gap: ac(111px, 80px);
    margin-top: $gap;
}

.meet-team--member {
    $gap: ac(117px, 80px);
    margin-top: $gap;
}

.meet-team--careers {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.meet-team {
    position: relative;

    &--careers {
        .meet-team {
            &__wrapper {
                flex-wrap: wrap;
                display: flex;
                margin-top: ac(40px, 20px);
            }

            &__slider {
                overflow: visible;
            }

            &__card {
                padding: ac(30px, 15px) ac(30px, 15px) ac(38px, 15px);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                border-radius: 40px;
                height: 100%;

                &:hover {
                    background-color: var(--white);
                }
            }
    
            &__img {
                width: 100%;
                height: ac(335px, 258px, 320, 1440);
                border-radius: 100%;
                overflow: hidden;
            }
    
            &__pos {
                margin: 8px auto 17px;
                line-height: ac(26px, 20px);
            }
    
            &__btn-box {
                margin-top: auto;
                position: relative;
                display: flex;
                width: 100%;
            }
    
            &__hidden {
                bottom: ac(200px, 130px);
                left: 0;
                right: auto;
                max-height: unset;
                visibility: hidden;

                &:last-child {
                    left: 0;
                }
            }
    
            &__btn {
                display: inline-flex;
                align-items: center;
                cursor: pointer;
    
                .arrow-btn {
                    margin-right: ac(10px, 5px, 651, 1440);
                    width: 29px;
                    height: 29px;
                    background-color: var(--golden);
    
                    span {
                        font-size: 14px;
                        line-height: 29px;
                        transform: rotate(90deg);
                        transition: .3s linear;
                        color: var(--tuna);
                    }
                }
    
                &>span {
                    &:last-child {
                        font-family: var(--font-sec);
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
    
                &--weak {
                    margin-left: ac(64px, 15px, 900, 1440);
                }
    
                &:hover {
                    .arrow-btn {
                        background-color: var(--blue);
    
                        span{
                            transform: none;
                        }
                    }
                }
    
                &--weak:hover~.meet-team__hidden--weak,
                &--str:hover~.meet-team__hidden--str {
                    opacity: 1;
                    visibility: visible;
    
                }
    
                &--weak:hover~.meet-team__hidden--weak {
                    left: auto!important;
                    right: 0!important;
                }
            }
        }
    }

    &--member {
        .meet-team__heading {
            margin-bottom: ac(40px, 25px);
        }
    }

    .slider-nav {
        margin-top: 20px;
        justify-content: center;
        width: 100%;
    }

    &__heading {
        max-width: 603px;
        margin-bottom: ac(39px, 25px);
    }

    &__subtitle {
        margin: ac(42px, 20px) 0 ac(22px, 18px);
        font-size: ac(27px, 20px);
        line-height: 120%;
    }

    &__decor {
        position: absolute;
        right: ac(210px, 30px, 1024, 1440);
        top: ac(-24px, 40px, 1024, 1440);
        width: ac(294px, 200px);
        height: ac(294px, 200px);

        img {
            object-fit: contain;
        }
    }
    &__slide {
        height: auto;
    }
    &__card {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border: 1px solid var(--tuna);
        border-radius: ac(199px, 30px);
        padding: ac(30px, 15px) ac(30px, 15px) ac(23px, 15px);
        transition: background-color .3s ease, transform .4s ease;

        &:hover {
            .meet-team__excerpt {
                opacity: 1;
            }
        
            .meet-team__preview:before {
                opacity: 0.5;
            }
        }
    }

    &__img {
        @mixin aspect-ratio 337, 337;
        border-radius: ac(199px, 30px);
    }

    &__preview {
        position: relative;

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--blue);
            z-index: 2;
            pointer-events: none;
            transition: 0.3s ease;
            border-radius: ac(199px, 30px);
            opacity: 0;
        }
    }



    &__icon {
        width: 29px;
        height: 29px;
        border-radius: 100%;
        background: var(--blue);
        margin: auto auto 0;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;

        i {
            color: var(--tuna);
            z-index: 20;
        }
    }

    &__name {
        text-align: center;
        margin: ac(21px, 15px) auto 0;
        font-size: ac(27px, 22px);
        line-height: ac(32px, 26px);
        font-weight: 600;
    }

    &__pos {
        margin: 13px auto ac(31px, 20px);
        text-align: center;
        font-size: ac(21px, 17px);
        font-weight: 600;
    }

    &__excerpt {
        color: var(--tuna);

        .simplebar-vertical {
            top: 15px !important;
            bottom: 15px !important;
        }
    }

    &__hidden,
    &__excerpt {
        position: absolute;
        bottom: 11px;
        left: 11px;
        right: 11px;
        max-height: 200px;
        max-width: 294px;
        background: var(--white);
        padding: 20px;
        border: 1px solid var(--tuna);
        border-radius: 40px;
        z-index: 5;
        transition: 0.3s ease;
        opacity: 0;
    }

    @media (min-width: 1024px) {
        .swiper {
            overflow: visible;
        }

        .swiper-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% + 15px);
            margin-bottom: -15px;

            &>* {
                margin-bottom: 15px;
                width: calc(100% / 3 - 15px);
                margin-right: 15px;
            }
        }
    }
    
    @mixin tab {
        &__decor {
            display: none;
        }

        &__btn-box {
            justify-content: center;
        }
    }
}

