.intro {
	$gap: ac(150px, 80px);
    margin-top: $gap;

	&__wrap {
        display: flex;
        align-items: center;
    }

    &__inner {
        max-width: ac(603px, 470px, 900, 1440);
    }

    &__title {
        max-width: 500px;
        margin-bottom: ac(42px, 20px);
    }

    &__subtitle {
        margin-bottom: ac(20px, 15px);
        font-weight: 600;
        font-size: ac(27px, 20px);
        line-height: 120%;
    }

    &__descr {
        p {
            margin-bottom: 10px;
        }
    }

    .btn {
        margin-top: 30px;
    }

	&__img {
        flex-shrink: 0;
		width: ac(596px, 250px, 320, 1440);
		height: ac(596px, 250px, 320, 1440);
        margin-left: ac(-198px, -100px, 992, 1440);
        margin-right: ac(118px, 25px, 992, 1440);

        img {
            border-radius: 100%;
        }
	}

    @mixin tab-md {
        &__wrap {
            flex-direction: column;
        }

        &__img {
            margin: 0 0 40px;
        }

        &__inner, &__title {
            max-width: unset;
        }
    }
}