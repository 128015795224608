.salary-guide {
    $gap: ac(60px, 35px);
    margin-top: $gap;

    &__form {
        max-width: 1014px;
        padding-right: ac(57px, 75px);

        .choices {
            &:nth-child(2) {
                min-width: unset;
            }
        }

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__title {
        margin-bottom: ac(36px, 15px);
        font-size: ac(37px, 28px);
        line-height: ac(50px, 34px);
        color: var(--blue);
    }

    &__btn-box {
        margin-top: ac(70px, 35px);
        display: flex;
        flex-wrap: wrap;
        margin-right: -13px;
        margin-bottom: -13px;

        .btn {
            margin-right: 13px;
            margin-bottom: 13px;
        }
    }

    &__download-box {
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-top: 15px;
        row-gap: 15px;
    }

    &__download {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc(100% / 3 - 15px);
        min-height: ac(209px, 170px);

        padding: ac(40px, 20px) ac(30px, 15px);
        margin-right: 15px;

        background-color: var(--white);
        border: 1px solid var(--tuna);
        border-radius: ac(40px, 30px);

        transition: .3s ease;

        &:hover {
            cursor: pointer;
            background-color: var(--tuna);

            .salary-guide__download-title {
                color: var(--white);
            }
        }
    }

    &__download-title {
        font-size: ac(25px, 23px);
        line-height: ac(30px, 26px);
        font-weight: 600;
        margin-bottom: 10px;
        transition: .3s ease;
    }

    @mixin tab {
        &__download {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin mob-xl {
        &__download {
            width: calc(100% - 15px);
        }
    }
}