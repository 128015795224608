.care {
	position: relative;
	$gap: ac(165px, 126px);
	margin-top: $gap;
	display: flex;
	border-radius: ac(393px, 30px);
	background-color: var(--alice);
	padding: 60px 0 ac(95px, 35px);

	&__wrapper {
		position: relative;
		padding-right: ac(100px, 50px, 1024, 1440);
	}

	&__bg {
		position: absolute;
		top: ac(-143px, -100px, 1024, 1440);
		left: ac(-256px, -100px, 1024, 1440);
		width: ac(750px, 450px, 1024, 1440);
		height: ac(750px, 450px, 1024, 1440);
		transform: translateX(-10vw);
		border-radius: 100%;
		overflow: hidden;
		transition: 0.3s ease;

		opacity: 0;
		visibility: hidden;

		img {
			object-position: center top;
		}

		&.active {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__col {
		width: 100%;
		max-width: 502px;
		margin-left: auto;
	}

	&__content {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;
		width: 100%;
		transition: 0.3s ease;
		transform: translateX(10vw);
		visibility: hidden;
		opacity: 0;

		&:nth-child(2) {
			position: absolute;
			top: 0;
			left: 0;
		}

		&.active {
			opacity: 1;
			visibility: visible;
			transform: translateX(0);
		}
	}

	&__icon {
		width: ac(66px, 51px);
		height: ac(66px, 51px);

		img {
			object-position: left;
			object-fit: contain;
		}
	}

	&__title {
		margin-top: ac(21px, 14px);
		max-width: 12ch;
	}

	&__descr {
		margin-bottom: ac(61px, 40px);
		margin-top: ac(39px, 31px);
		width: calc(100% + 10px);

		p {
			font-weight: 600;
			font-size: ac(27px, 20px);
			line-height: ac(33px, 24px);
		}

		ul {
			margin-top: .5em;
			padding-left: 25px;

			li {
				position: relative;

				&::before {
					content: '';
					position: absolute;
					left: -14px;
					top: 9px;
					border-radius: 100%;
					width: 4px;
					height: 4px;
					background-color: var(--tuna);
				}
			}
		}

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: ac(280px, 355px);
		}

		.simplebar-content {
			padding-right: 30px !important;

			& > * {
				&:not(:last-child) {
					margin-bottom: ac(30px, 21px);
				}

				&:last-child {
					margin-bottom: 1px;
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			right: 20px;
		}

		.simplebar-vertical {
			width: 5px;
			background-color: rgb(5, 142, 217, .2);
		}

		.simplebar-scrollbar::before {
			background-color: var(--blue);
		}
	}

	&__btn-box {
		margin-top: auto;
		display: flex;
		flex-wrap: wrap;
		margin-right: -14px;
		margin-bottom: -15px;

		.btn {
			margin-right: 14px;
			margin-bottom: 15px;
		}
	}

	&__btn {
		position: absolute;
		bottom: ac(12px, 30px);
		left: ac(207px, 120px);

		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		width: ac(191px, 120px);
		height: ac(191px, 120px);
		background-color: var(--alice);
		border: 1px solid var(--tuna);
		border-radius: 100%;

		visibility: hidden;
		opacity: 0;
		transition: 0.3s ease;
		z-index: 1;
		cursor: pointer;

		&.active {
			opacity: 1;
			visibility: visible;
		}

		p {
			text-align: center;
			font-weight: 700;
			font-size: ac(27px, 18px);
			line-height: ac(32px, 24px);
			transition: 0.3s ease;
		}

		.arrow-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: var(--blue);
			width: 29px;
			height: 29px;
			transform: translateY(ac(29px, 18px, 1023, 1440)) rotate(-135deg);

			span {
				color: var(--tuna);
				font-size: 18px;
				line-height: 29px;
			}
		}

		&:hover {
			background-color: var(--golden);
			p {
				color: var(--white);
			}

			.arrow-btn {
				span {
					color: var(--white);
				}
			}
		}
	}

	@mixin tab {
		padding-top: 130px;
		border-radius: 31px;

		&__bg {
			top: ac(-240px, -200px, 320, 1023);
			left: unset;
			right: 0px;
			width: ac(400px, 200px, 320, 1024);
			height: ac(400px, 200px, 320, 1024);
		}

		&__btn {
			top: ac(-60px, -50px, 320, 1024);
			left: unset;
			right: ac(550px, 15px);

			.arrow-btn {
				transform: translateY(ac(18px, 10px, 320, 1024)) rotate(-135deg);
			}
		}

		&__col,
		&__descr {
			max-width: unset;
		}

		&__wrapper {
			padding-right: 0;
		}
	}

	@mixin mob-lg {
		&__btn-box {
			flex-direction: column;
			align-items: flex-start;
		}
	}

}
