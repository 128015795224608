.mission.mission--careers {
	$gap: ac(149px, 120px);
    margin-top: $gap;
}

.mission {
	position: relative;
	$gap: ac(147px, 99px);
	margin-top: $gap;
	padding-bottom: ac(45px, 0px, 1024, 1440);
	z-index: 2;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: ac(274px, 160px);
		background-color: var(--tuna);
		z-index: -1;
	}

	&__title {
		max-width: ac(603px, 560px, 1024, 1440);
		margin-left: ac(104px, 0px, 1024, 1440);

		span {
			color: var(--blue);
		}
	}

	&__wrapper {
		flex-wrap: wrap;
		display: flex;
		margin-top: ac(64px, 47px);
	}

	&__slider-box {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		margin-top: ac(90px, 20px);

		.slider-nav {
			margin: 0 0 20px auto;
		}
	}

	&__slider {
		overflow: visible !important;
		min-height: ac(350px, 340px);

		.mission__card {
			height: 100%;
		}
	}

	.swiper-slide {
		&:nth-child(4n + 1) {
			.mission__icon {
				width: ac(51px, 53px);
				height: ac(63px, 53px);
				transform: translateY(ac(-4px, 0px));
			}
		}

		&:nth-child(4n + 2) {
			.mission__icon {
				width: ac(72px, 53px);
				height: ac(55px, 53px);
				transform: translateY(ac(-4px, 0px));
			}
		}

		&:nth-child(4n + 3) {
			.mission__icon {
				width: ac(40px, 53px);
				height: ac(74px, 53px);
				transform: translateY(ac(-6px, 0px));
			}
		}

		&:nth-child(4n + 4) {
			.mission__icon {
				width: ac(67px, 53px);
				height: ac(55px, 53px);
				transform: translateY(ac(-5px, 0px));
			}
		}
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		padding: 0 22px 31px;
		width: 100%;
		min-height: ac(295px, 226px, 1024, 1440);
		transition: 0.3s ease;
		cursor: pointer;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 100%;
			width: 100%;
			height: 100%;
			background-color: var(--golden);
			z-index: -1;
			transition: .3s ease;
		}

		&:hover {
			&::before {
				background-color: var(--blue);
			}

			.mission__btn {
				background-color: var(--blue);
			}
		}

		&.active {
			&::before {
				background-color: var(--white);
				box-shadow: 0 0 2px 2px var(--golden);
				border-radius: 8px;
			}

			.mission__text {
				max-height: 132px;
				opacity: 1;
				transition: all .3s ease;
			}

			.mission__btn {
				transform: translate(-50%, 50%) rotate(180deg);
			}

			.mission__icon-box {
				&::before {
					transform: translateY(-100%);
				}
			}

			.mission__name {
				margin-top: 0;
			}

			&:hover {
				&::before {
					background-color: var(--golden);
				}
			}	
		}
	}

	&__btn {
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 50%);

		z-index: 1;

		span {
			pointer-events: none;
			font-size: ac(24px, 19px);
		}
	}

	&__icon-box {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		width: ac(141px, 91px);
		height: ac(120px, 84px);
		margin: 0 auto;
		overflow: hidden;

		&::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--white);
			border-radius: 0 0 ac(70px, 30px) ac(70px, 30px);
			transition: 0.3s ease;
		}
	}

	&__icon {
		z-index: 1;
		width: ac(63px, 53px);
		height: ac(63px, 53px);

		img {
			object-fit: contain;
		}

	}

	&__name {
		margin-bottom: 10px;
		margin-top: ac(37px, 30px);
		font-weight: 600;
		font-size: ac(27px, 20px);
		line-height: ac(34px, 24px);
		text-align: center;
		transition: 0.3s ease;
	}

	&__text {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height 0.3s ease;
		width: calc(100% + 15px);
	}

	&__descr {
		font-weight: 300;
		font-size: ac(16px, 14px);
		line-height: ac(22px, 20px);
		transition: 0.3s ease;
        padding-right: 15px;
		max-height: calc(ac(22px, 20px) * 5);

        .simplebar-vertical {
            width: 7px;
            background-color: rgba(43, 50, 69, .3);
            border-radius: 7px;
        }

        .simplebar-scrollbar::before {
            background-color: var(--tuna);
        }

		.simplebar-content {
			& > * {
				&:not(:last-child) {
					margin-bottom: ac(30px, 21px);
				}
			}
		}
	}
	
	&--careers {
		padding-bottom: ac(149px, 100px);

		&::before {
			height: ac(323px, 160px);
			border-radius: ac(161px, 30px);
		}

		.mission {
			&__slider {
				min-height: unset;
			}

			&__slider-box {
				margin-top: ac(66px, 45px);
			}

			&__card {
				width: 100%;
				margin-right: 0;
				margin-bottom: 0;
				pointer-events: none;
			}

		}
	}

	&--org {
		&::before {
			height: ac(250px, 160px);
		}

		.mission__icon {
			margin-top: ac(-9px, 0px, 1024, 1440);
		}

		.mission__card {
			padding: 0 22px 31px;
		}

		.swiper-slide {
			height: auto;

			&:nth-child(4n + 1) {
				.mission__icon {
					width: ac(64px, 53px);
					height: ac(64px, 53px);
					transform: translateY(0);
				}
			}
	
			&:nth-child(4n + 2) {
				.mission__icon {
					width: ac(64px, 53px);
					height: ac(64px, 53px);
					transform: translateY(0);
				}
			}
	
			&:nth-child(4n + 3) {
				.mission__icon {
					width: ac(60px, 53px);
					height: ac(60px, 53px);
					transform: translateY(0);
				}
			}
	
			&:nth-child(4n + 4) {
				.mission__icon {
					width: ac(61px, 53px);
					height: ac(63px, 53px);
					transform: translateY(ac(-4px, 0px));
				}
			}
		}
	}

	@mixin tab {
		&--careers {
			&::before {
				height: ac(297px, 180px);
			}

			.slider-nav {
				position: absolute;
				margin: 0;
				bottom: 30px;
				left: 50%;
				transform: translateX(-50%);
	
				.arrow-btn {
					background-color: var(--golden);
	
					span {
						color: var(--tuna)
					}
	
					&:hover {
						background-color: var(--blue);
	
					span {
						color: var(--white);
					}
					}
				}
			}

			.mission__card {
				min-height: 200px;
			}
		}

		&__card {
			min-height: 250px;

			&::before {
				border-radius: ac(100px, 30px);
			}
		}
	
	}

	@mixin mob-lg {
		&--careers {
			&::before {
				height: ac(297px, 200px);
			}
		}

	}
}
