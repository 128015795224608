.gallery {
    $gap: ac(140px, 70px);
    margin-top: $gap;

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
    }

    &__slider {
        overflow: visible !important;
    }

    .slider-nav {
        margin: 20px auto 0;
    }

    .swiper-slide {
        display: flex;
    }

    &__img {
        width: 100%;
        height: ac(282px, 220px, 1024, 1440);

        img {
            border-radius: 100%;
        }
    }

    @mixin tab {
        &__img {
            height: ac(305px, 180px, 650, 1024);
        }
    } 

    @mixin mob-xl {
        &__img {
            height: ac(280px, 170px, 431, 650);
        }
    }

    @mixin mob {
        &__img {
            height: ac(355px, 290px, 320, 431);
        }
    }
}