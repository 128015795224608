.pay-rates {
    position: relative;
    $gap: ac(135px, 90px);
    margin-top: $gap;

    &__decor {
        position: absolute;
        right: ac(140px, 20px, 768, 1440);
        top: ac(-11px, -60px, 768, 1440);
        width: ac(191px, 60px);
        height: ac(192px, 60px);

        img {
            object-fit: contain;
        }
    }

    &__title {
        margin-bottom: ac(39px, 25px);
    }

    &__form {
        max-width: 706px;
        padding-right: ac(57px, 75px);

        .choices {
            &:nth-child(2) {
                min-width: unset;
            }
        }

        .first__submit {
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__slider-box {
        margin-top: -39px;
        background-color: var(--tuna);
        color: var(--white);
        padding: ac(108px, 80px) 0 ac(100px, 65px);
        border-radius: ac(80px, 30px);
    }

    .slider-nav {
        position: absolute;
        right: ac(142px, 80px, 768, 1440);
        top: -4px;

        .arrow-btn span {
            font-size: ac(31px, 22px);
        }
    }

    &__card {
        display: flex;
    }

    &__text-box {
        padding-right: ac(27px, 15px);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__name {
        display: inline-block;
        margin-bottom: 30px;
        padding-bottom: 2px;
        border-bottom: 2px solid var(--blue);
        font-size: ac(32px, 24px);
        line-height: ac(39px, 32px);
        font-weight: 600;
    }

    &__fact {
        font-size: ac(17px, 14px);
        margin-bottom: 14px;
        font-weight: bold;
    }

    &__marked {
        font-size: ac(21px, 17px);
        color: var(--golden);
        margin-bottom: 10px;
    }

    &__descr {
        max-width: 620px;
        width: 100%;
        max-height: 88px;
        margin-bottom: ac(46px, 25px);

        .details__text-box {
            padding-right: 15px;
        }

        .simplebar-track.simplebar-vertical {
            width: 5px;
            background-color: rgba(249, 218, 65, .2);
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--golden);
        }
    }

    &__options {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        width: 100%;
        gap: 25px;
        margin-bottom: ac(24px, 15px);

        li {
            &:nth-child(2) {
                transform: translateX(ac(4px, 0px, 1024, 1440));
            }

            &:nth-child(3) {
                transform: translateX(ac(9px, 0px, 1024, 1440));
            }
        }
    }

    &__key {
        font-size: ac(27px, 20px);
        font-weight: 700;
        margin-bottom: ac(26px, 15px);
    }

    &__value {
        font-size: ac(21px, 17px);
        color: var(--golden);
    }

    &__summary {
        font-size: ac(21px, 17px);
        color: #F3F6FF;
        opacity: 0.5;
    }

    &__img-box {
        width: 397px;
        max-width: 40%;
        flex-shrink: 0;
        margin-top: 53px;
        align-self: flex-start;
    }

    &__caption {
        width: 100%;
        margin-bottom: 26px;
        text-align: center;
        padding-left: ac(14px, 0px);
        color: var(--white);

        &:hover {
            color: var(--blue);
        }
    }

    &__img {
        position: relative;
        overflow: hidden;

        &::before {
            content: '';
            display: block;
            padding-top: 71.28%;
        }

        picture {
            img {
                position: absolute;
                left: 0;
                top: 0;
                width: calc(50% - 15px / 2);
                border-radius: ac(30px, 15px);
            }

            &:last-of-type {
                img {
                    left: auto;
                    right: 0;
                }
            }
        }

    }

    @mixin tab-sm {
        &__card {
            flex-direction: column;
        }

        &__img-box {
            max-width: 100%;
            margin: 40px auto 0;
            display: flex;
            flex-direction: column-reverse;
        }

        &__caption {
            margin-bottom: 0;
            margin-top: 25px;
        }

        &__descr {
            max-width: unset;
        }

        &__text-box {
            padding-right: 0;
        }

        .slider-nav {
            top: unset;
            bottom: -55px;
            right: unset;
            left: 50%;
            transform: translateX(-50%);
        }
    
        &__options {
            justify-content: flex-start;
        }
    }
}
