.commitments {
    $gap: ac(150px, 80px);
    margin-top: $gap;
    background-color: var(--blue);
    border-radius: ac(447px, 200px, 1024, 1440);

    &__decor {
        position: absolute;
        left: -205px;
        top: -48px;
        width: ac(190px, 100px);
        height: ac(285px, 200px);
        z-index: -1;
     
        img {
            object-fit: contain;
        }
    }

    &__wrapper {
        position: relative;
        width: 100%;
        max-width: ac(929px, 700px);
        margin-left: ac(207px, 120px, 1024, 1440);
        padding: 79px 0 100px;
        z-index: 1;
    }

    &__title {
        margin-bottom: ac(60px, 25px);
        color: var(--white);
    }

    &__list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-right: -15px;
        margin-bottom: ac(-37px, -20px);
    }

    &__item {
        width: calc(100% / 3 - 15px);
        margin-right: 15px;
        margin-bottom: ac(37px, 20px);
    }

    &__icon {
        width: 38px;
        height: 38px;

        img {
            object-fit: contain;
        }
    }

    &__name {
        margin: ac(20px, 15px) 0 5px;
        color: var(--white);
    }

    &__desc {
        &, &>* {
            font-size: ac(21px, 18px);
            line-height: ac(26px, 22px);
            color: var(--white);
        }
    }

    @mixin tab {
        border-radius: 30px;

        &__wrapper {
            max-width: unset;
            padding: 50px 0;
            margin-left: 0;
        }

        &__decor {
            display: none;
        }

        &__item {
            width: calc(100% / 2 - 15px);
        }
    }

    @mixin tab-sm {
       &__item {
            width: calc(100% - 15px);
        }
    }
}


