.supply {
	position: relative;
	$gap: ac(150px, 100px);
	margin-top: $gap;

	&__btn-list {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		gap: ac(29px, 15px);
		margin-bottom: ac(40px, 20px);
		
		.btn {
			cursor: pointer;
		}
	}

	.banner {
		margin-top: 0;
		margin-bottom: 15px;

		&__wrapper {
			padding-bottom: 50px;
			border-radius: 42px;
		}

		&__subtitle {
			max-width: 850px;
		}

		&__btn {
			margin-bottom: 15px;
		}

		&__title {
			margin-bottom: ac(32px, 20px);
		}

		&__bg {
			width: ac(400px, 224px);
			height: ac(380px, 210px);
			right: ac(-16px, -100px);
			top: ac(-141px, -39px);
		}
	}

	&__wrapper {
		display: flex;
	}

	&__card {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 100%;
		height: 100%;
		align-items: flex-start;
		padding: ac(38px, 20px, 1024, 1440) ac(30px, 20px, 1024, 1440) ac(42px, 20px, 1024, 1440);
		border-radius: 40px;
		background: var(--blue);
		color: var(--white);
		overflow: hidden;
		z-index: 1;
		cursor: pointer;

		.btn {
			margin: auto 0 0 auto;
			position: relative;
			z-index: 5;
			pointer-events: none;
		}

		&:hover {
			.btn {
				background-color: var(--alice);

				.arrow-btn span {
					color: var(--white);
				}
			}
		}
	}

	&__title {
		font-family: var(--font-sec);
		color: var(--white);
		font-weight: 700;
		margin-bottom: 24px;
		font-size: ac(37px, 28px);
		line-height: ac(44px, 34px);
		position: relative;
		z-index: 5;
	}

	&__descr {
		color: var(--white);
		font-weight: 600;
		font-size: ac(27px, 20px);
		font-family: var(--font-sec);
		line-height: ac(32px, 24px);
		max-width: 234px;
		margin-bottom: 13px;
		position: relative;
		z-index: 5;
		max-height: 128px;

		@mixin max-line-length 4;
	}

	&__banner {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: ac(38px, 20px, 1024, 1440) ac(30px, 20px, 1024, 1440);
		border-radius: 7px;
		background-color: var(--golden);
		overflow: hidden;

		.supply__title {
			margin-top: auto;
			margin-bottom: 0;
		}

		.btn {
			margin-top: ac(61px, 48px, 1024, 1440);
			border-color: var(--white);
		}

		&:hover {
			.btn {
				background-color: var(--golden);
				border-color: var(--tuna);

				.arrow-btn span {
					color: var(--white);
				}
			}
		}
	}

	&__bg {
		position: absolute;
		z-index: -1;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	&__slider-box {
		display: flex;
	}

	&__slider {
		overflow: visible !important;
	}

	.swiper-slide {
		&:first-child {
			.supply__bg {
				top: -65px;
				right: -135px;
				width: 320px;
				height: 287px;

				@mixin media 1081{
					right: -155px;
				}
			}
		}

		&:nth-child(2) {
			.supply__bg {
				right: -52px;
				top: -25px;
				width: 182px;

				img {
					transform: rotate(-40deg);
				}
			}
		}

		&:nth-child(3) {
			.supply__bg {
				width: 282px;
				bottom: 0;
				left: -31px;

				@mixin media 1081{
					bottom: -20px;
				}
			}
		}

		&:nth-child(4) {
			.supply__bg {
				bottom: -145px;
				left: -180px;
				width: 565px;

				img {
					transform: scaleX(-1);
				}
			}
		}
	}

	@media (min-width: 1024px) {
		.swiper-slide {
			width: calc(100% / 4 - 15px);
			margin-right: 15px;
		}

		.swiper-wrapper {
			width: auto;
			margin-right: -15px;
		}
	}

	@mixin tab {
	
		&__wrapper {
			flex-direction: column;
		}

		&__nav {
			margin-top: 20px;
			display: flex;
			justify-content: center;
			width: 100%;
			user-select: none;
			
			.arrow-btn {
				margin: 0 5px;
				transform: rotate(15deg);
				width: ac(48px, 37px);
				height: ac(48px, 37px);

				span {
					line-height: ac(48px, 37px);
					font-size: ac(28px, 22px);
					transform: rotate(-15deg);
				}
			}
		}

		&__banner {
			margin-top: 100px;
			padding: 30px;
		}

		&__card {
			padding: 30px;
		}
	}

	@mixin mob-lg {
		&__banner {
			min-height: 483px;
		}

		&__title {
			max-width: 210px;
		}
	}
}
