.travel {
    $gap: ac(150px, 70px);
    margin-top: $gap;
}

.travel--org {
    $gap: ac(121px, 70px);
    margin-top: $gap;
}

.travel {
    position: relative;
    z-index: 1;

    &--org {
        .travel__wrapper {
            max-width: unset;
        }

        .first__submit {
            .icon-arrow {
                color: var(--blue);
            }

            &:hover {
                .icon-arrow {
                    color: var(--white);
                }
            }
        }

        .travel__input-box {
            flex-wrap: wrap;
            
            &:last-child {
                margin-bottom: ac(-33px, -15px);
            }
        
            label {
                margin-bottom: ac(33px, 15px);
            }
        }
    }

    &__wrapper {
        max-width: 836px;
    }

    &__title {
        margin-bottom: ac(40px, 25px);
    }

    &__subtitle {
        margin-bottom: ac(18px, 10px);
        max-width: 603px;
        color: var(--blue);

        &--lg {
            margin-top: ac(70px, 35px);
            margin-bottom: ac(21px, 15px);
            font-size: ac(21px, 18px);
        }
    }

    &__descr {
        max-width: 706px;
        line-height: 22px;
    }

    &__form {
        margin-top: ac(40px, 25px);
    }

    &__terms {
        max-width: 603px;
        margin-top: ac(47px, 30px);

        p {
            &:first-child {
                margin-bottom: 9px;
            }

            &:last-child {
                color: var(--blue);
                font-size: 14px;
                line-height: 1.2;
            }
        }
    }

    &__input-box {
        display: flex;

        &:last-child {
            margin-bottom: ac(-34px, -15px);
        }
    
        label {
            font-size: ac(31px, 16px);
            font-weight: 700;
            display: flex;
            align-items: center;
            margin-bottom: ac(34px, 15px);

            span {
                flex-shrink: 0;
                margin-right: 10px;
            }
        }
    
        .arrow-btn {
            margin-top: -12px;
            transform: translateY(ac(3px, 0px, 1024, 1440));
        }
    }

    &__input {
        border-bottom: 2px solid var(--blue);
        font-size: inherit;
        font-weight: inherit;
        line-height: normal;
    
        &::placeholder {
            opacity: 0.5;
        }
    }

    @mixin tab-sm {
        &--org {
            .travel__input-box {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &__input-box {
            flex-wrap: wrap;

            &:last-child {
                margin-bottom: 0;
            }
    
            label {
                width: 100%;
            }
            
            .arrow-btn {
                margin: 0 0 0 auto;
            }
        }

        &__input {
            width: 100%;
        }
    }
}





