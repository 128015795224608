.partnership {
    $gap: ac(131px, 99px);
    margin-top: $gap;

    &__heading {
        max-width: 603px;
    }

    &__desc {
        margin-top: 40px;

        &, &>* {
            font-weight: 600;
            font-size: ac(27px, 20px);
            line-height: 120%;
        }
    }

    &__wrap {
        gap: 20px;
    }

    &__title {
        margin-top: ac(20px, 0px, 1024, 1440);
    }

    &__link {
        display: flex;
        align-items: center;
        padding: ac(30px, 15px) ac(30px, 15px) ac(30px, 15px) ac(103px, 15px);
        position: relative;
        flex-shrink: 0;
        width: 100%;
        max-width: ac(500px, 430px, 1024, 1440);
        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            height: 100%;
            background: var(--tuna);
            width: 100%;
            border-radius: ac(54px, 30px);
            z-index: -1;
            transition: 0.3s ease;
        }

        i {
            transform: rotate(-45deg);
            font-size: 24px;
            transition: .3s ease;
        }

        .arrow-btn {
            background: var(--golden);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 20px;
        }

        h3 {
            margin-bottom: ac(-3px, 0px, 1024, 1440);
            font-size: ac(27px, 20px);
            color: var(--white);
            transition: color 0.3s ease;
        }

        &:hover {
            h3 {
                color: var(--tuna);
            }

            .arrow-btn {
                background: var(--tuna);
            }

            &::before {
                background: var(--golden);
            }

            i {
                color: var(--golden);
            }
        }
    }

    @mixin tab-md {
        &__wrap {
            flex-direction: column-reverse;
            gap: 40px;
        }

        &__link {
            justify-content: center;
            padding: * 20px;

            &:before {
                left: auto;
                right: 0;
            }
        }
    }
}