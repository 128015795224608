.testimonials {
    $gap: ac(149px, 80px);
    margin-top: $gap;
    position: relative;
    border-radius: ac(226px, 200px, 902, 1440);
    background-color: var(--alice);
    z-index: 1;

    &__bg {
		position: absolute;
		top: calc(50% + 15px);
		right: ac(-259px, -145px, 1024, 1440);
		height: ac(570px, 300px, 1024, 1440);
		width: ac(570px, 300px, 1024, 1440);
		transform: translateY(-50%);
        z-index: -1;

		img {
			object-fit: contain;
		}
	}

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
    }

    .slider-nav {
        margin: 20px auto 0;
    }

    &__slider {
        overflow: visible !important;
    }

    &__slide {
        display: flex;
        align-items: center;
        opacity: 0;
        transition: .3s ease;

        &.swiper-slide-active {
            opacity: 1;
        }
    }

    &__img {
        flex: 0 0 auto;
        margin-left: ac(-110px, -38px, 902, 1440);
        margin-right: ac(176px, 35px, 902, 1440);
        width: 100%;
        max-width: ac(450px, 290px);
        height: ac(450px, 290px);
        border: 36px solid var(--blue);
        border-radius: 100%;
        overflow: hidden;
    }

    &__content {
        padding: 54px 0 40px;
        max-width: ac(500px, 400px, 902, 1440);
    }

    &__quotes {
        font-size: 45px;
        font-weight: 700;
        font-family: 'Arial', sans-serif;
        margin-bottom: 8px;
        display: block;
    }

    &__descr {
        font-size: ac(26px, 20px);
        line-height: ac(32px, 26px);
        margin-bottom: ac(34px, 15px);
    }

    &__name {
        font-size: ac(21px, 18px);
        margin-bottom: 8px;
        font-weight: 600;
    }

    @mixin tab-md {
        border-radius: 30px;
        padding: 40px 0;

        &__bg {
            display: none;
        }

        &__slide {
            flex-direction: column;
            align-items: center;
        }

        &__img {
            margin: 0;
            border-width: 20px;
        }
    
        &__content {
            max-width: unset;
            margin: ac(40px, 25px) 0 0;
            padding: 0;
        }
      
    }
}


