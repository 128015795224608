.win {
	$gap: ac(146px, 65px);
	margin-top: $gap;
}

.win--careers {
	$gap: ac(123px, 80px);
	margin-top: $gap;
}

.win {
	&__wrapper {
		display: flex;
		align-items: center;
	}

	&__col {
		padding-top: ac(56px, 0px, 1024, 1440);
		width: 100%;
	}

	&__title {
		margin-left: ac(3px, 0px, 1024, 1440);
	}

	&__descr {
		margin-top: ac(40px, 30px);
		font-size: ac(32px, 20px);
		line-height: 124%;
		font-weight: 600;
	}

	&__btn {
		margin-top: 29px;
	}

	&__video-box {
		flex: 0 0 auto;
		position: relative;
		width: ac(560px, 450px, 1024, 1440);
		height: ac(560px, 450px, 1024, 1440);
		margin-left: ac(59px, 35px, 1024, 1440);
		transform: translateX(27px);
		border-radius: 100%;
		border: ac(30px, 10px) solid var(--golden);
		overflow: hidden;
		z-index: 1;

		&>div {
			border-radius: 100%;
			width: 100%;
			height: 100%;
		}
		
		.plyr__controls {
			width: ac(400px, 360px);
			bottom: ac(100px, 80px, 1024, 1440) !important;
			background: transparent !important;
			left: 50% !important;
			transform: translateX(-50%) !important;
		}
	}

	&--careers {
		.win__video-box {
			transform: translate(ac(30px, 0px, 1024, 1440), ac(30px, 0px, 1024, 1440));
		}
	}

	@mixin tab {
		&__wrapper {
			flex-direction: column;
		}

		&__video-box {
			margin-left: 0;
			margin-top: 35px;
			width: ac(450px, 290px, 320, 1024);
			height: ac(450px, 290px, 320, 1024);
			transform: translateX(0);

			.plyr__controls {
				width: ac(360px, 250px, 320, 1024) !important;
				bottom: ac(80px, 60px, 320, 1024) !important;
			}
		}
	}
}
