.positions.positions--careers {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.positions.positions--prof {
    $gap: ac(83px, 80px);
    margin-top: $gap;
}

.positions {
    $gap: 15px;
    margin-top: $gap;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-right: -15px;
    }

    &__card {
        display: flex;
        flex-direction: column;
        padding: ac(18px, 10px) ac(30px, 15px) ac(37px, 15px);
        width: calc(100% / 3 - 15px);
        border-radius: ac(40px, 30px);
        border: 1px solid var(--tuna);
        background-color: var(--white);
        min-height: ac(345px, 230px);
        transition: .3s ease !important;

        &:hover {
            background-color: var(--tuna);
            color: var(--white);

            .positions__btn {
                background-color: var(--white);

                .icon-arrow {
                    color: var(--tuna)
                }
            }
        }
    }

    &__btn {
        margin-left: auto;
        margin-right: ac(-12px, -6px);
        width: 29px;
        height: 29px;

        .icon-arrow {
            line-height: 29px;
            font-size: 13px;
        }
    }

    &__title {
        margin-bottom: auto;
        font-weight: 700;
        font-size: ac(37px, 24px);
        line-height: ac(45px, 32px);
    }

    &__text {
        margin-top: 11px;
        font-weight: 600;
        font-size: ac(27px, 18px);
        line-height: ac(30px, 24px);
    }

    &--prof,
    &--careers {
        padding-bottom: 141px;
        position: relative;
        z-index: 1;

        .first__form {
            margin-bottom: 14px;

            &--hidden {
                margin-bottom: 0;
                margin-top: 0;
            }
        }

        .first__tag-box {
            align-items: flex-end;
        }

        .positions {
            &__form {
                position: relative;
                margin-top: ac(40px, 20px);
                z-index: 20;
                max-width: ac(960px, 800px, 1024, 1440);
            }

            &__subtitle {
                margin-top: ac(40px, 20px);
                font-weight: 600;
                font-size: ac(27px, 18px);
                line-height: ac(34px, 22px);
            }
            
            &__slider-box {
                position: relative;
                display: flex;
                margin-top: ac(53px, 30px);
                z-index: 10;
            }

            &__slider {
                overflow: visible !important;
            }

            &__card {
                width: 100%;
                height: 100%;

                &:hover {
                    border-color: var(--white);
                }
            }

            &__nav {
                position: absolute;
                right: 0;
                top: ac(-97px, -65px, 1024, 1440);
            }
        }
    }

    &--careers {
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: ac(400px, 300px);
            background-color: var(--tuna);
            z-index: -1;
            border-radius: ac(200px, 30px);
        }

        .positions  {
            &__nav {
                top: ac(-87px, -65px, 1024, 1440);
            }

            &__slider-box {
                margin-top: ac(56px, 30px);
            }
        }
    }

    &--prof {
        padding-top: ac(80px, 40px);
        padding-bottom: ac(44px, 105px, 1023, 1024);

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: calc(100% - 303px);
            background-color: var(--alice);
            border-radius: ac(236px, 30px);
            z-index: -1;
        }

        .first__form {
            max-width: 809px;
            margin-bottom: 19px;
            margin-left: ac(-3px, 0px, 1024 , 1440);

            .choices {
                &:nth-child(2) {
                    min-width: unset;
                }
            }
        }

        .first__accardion {
            margin-left: ac(-3px, 0px, 1024 , 1440);
        }

        .first__accardion-head {
            flex: 0 0 auto;
            margin-left: auto;
            width: 150px;
            padding: 0;
            transform: translate(-5px, -6px);

            span.icon-arrow {
                right: 0;
                top: 2px;
            }

            &.open {
                transform: translate(-51px, 1px);

                span.icon-arrow {
                    right: -42px;
                }
            }
        }

        .first__tags {
            margin-left: 0;

            a {
                margin-right: ac(19px, 10px);
            }
        }

        .positions {
            &__form {
                max-width: 811px;
                margin-top: ac(50px, 25px);
                margin-left: ac(211px, 0px, 1024, 1440);

                input {
                    max-width: 405px;
                }
            }

            &__slider-box {
                position: relative;
                margin-top: ac(49px, 30px);

                &::before {
                    content: '';
                    position: absolute;
                    bottom: ac(-44px, -105px, 1023, 1024);
                    left: -50vw;
                    width: calc(100% + 100vw);
                    height: 303px;
                    background-color: var(--tuna);
                    z-index: -1;
                }
            }

            &__title-box {
                display: flex;
                margin-left: ac(207px, 0px, 1024, 1440);

                h3 {
                    flex: 0 0 auto;
                    width: 100%;
                    max-width: 243px;
                    margin-right: ac(66px, 20px, 1024, 1440);
                }
            }

            &__subtitle {
                margin-top: 0;
                font-size: ac(21px, 16px);
                line-height: ac(26px, 22px);
            }
        }
    }

    .swiper-wrapper {
        &>h3 {
            min-height: ac(345px, 370px);
            transform: translateY(ac(-20px, -10px));
        }
    }

    @mixin tab {
        &__card {
            width: calc(100% / 2 - 15px);
        }

        &--prof,
        &--careers {
            .positions__nav {
                top: unset;
                right: unset;
                left: 50%;
                transform: translateX(-50%);
                bottom: -75px;

                .positions__prev {
                    margin-right: 10px;
                }

                .arrow-btn {
                    background-color: var(--white);
                    border: 1px solid var(--tuna);

                    span {
                        color: var(--tuna);
                    }

                    &:hover {
                        background-color: var(--tuna);
                        border: 1px solid var(--white);

                        span {
                            color: var(--white);
                        }
                    }
                }
            }
        }

        &--prof {
            .positions__title-box {
                flex-direction: column;

                h3 {
                    max-width: unset;
                    margin-right: 0;
                }
            }

            .positions__subtitle {
                margin-top: 20px;
                max-width: unset;
            }
        }
    }

    @mixin mob-xl {
        &__card {
            width: calc(100% - 15px);
        }
    }
}