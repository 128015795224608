.counter {
	$gap: ac(109px, 65px);
	margin-top: $gap;
}

.counter--org {
	$gap: ac(150px, 99px);
	margin-top: $gap;
}

.counter {
	.slider-nav {
		margin-top: ac(20px, 60px);
		display: flex;
		justify-content: center;
		width: 100%;
		user-select: none;
		
		.arrow-btn {
			margin: 0 5px;
			transform: rotate(15deg);
			width: ac(48px, 37px);
			height: ac(48px, 37px);
			background-color: var(--golden);

			span {
				color: var(--tuna);
				line-height: ac(48px, 37px);
				font-size: ac(28px, 22px);
				transform: rotate(-15deg);
			}

			&:hover {
				background-color: var(--blue);

				span {
					color: var(--white);
				}
			}
		}

	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		max-width: 1015px;
		width: 100%;
		margin: 0 auto;
	}

	&__slider {
		overflow: visible !important;
	}

	&__item {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	&__val {
		position: relative;
		font-weight: 700;
		padding-top: 5px;
		font-size: ac(53px, 38px);
		line-height: ac(60px, 46px);
		color: var(--blue);

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 50%;
			width: 136px;
			height: 136px;
			border-radius: 100%;
			border: 35px solid var(--alice);
			transform: translateX(-50%);
			z-index: -1;

		}
	}

	&__title {
		margin-top: 23px;
		font-weight: 600;
		font-size: ac(21px, 16px);
		line-height: ac(26px, 19px);
		text-align: center;
		word-break: break-word;
	}

	@mixin mob {
		&__wrapper {
			margin-right: 0;
		}

		&__item {
			max-width: 100%;
			margin-right: 0;
		}
	}
}
