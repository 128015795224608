.team-video {
    $gap: ac(150px, 80px);
    margin-top: $gap;
}

.team-video--prof {
    $gap: ac(-60px, 0px, 1024, 1440);
    margin-top: $gap;
}

.team-video--org {
    $gap: 0;
    margin-top: $gap;
}

.team-video {
    padding: 80px 0 ac(115px, 80px);
    color: var(--white);
    background: var(--tuna);
    border-radius: ac(414px, 30px, 768, 1440);
    
    .plyr {
        border-radius: ac(40px, 30px);
    }

    &--prof {
        border-radius: 0;
        padding: ac(0px, 20px, 1024, 1440) 0 ac(151px, 80px);

        .contacts__link {
            font-size: 18px;
            font-weight: 600;
        }

        .btn {
            margin-top: 11px;
        }

        .plyr {
            border-radius: 20px;
        }

        .team-video {
            &__decor {
                width: ac(294px, 180px, 320, 1440);
                height: ac(294px, 180px, 320, 1440);
                right: ac(-76px, -30px, 320, 1440);
                top: ac(105px, 50px, 320, 1440);
                z-index: -1;
            }

            &__wrapper {
                display: flex;
                align-items: flex-start;
                max-width: 1015px;
                margin: 0 auto;
                position: relative;
                gap: 15px;
                justify-content: space-between;
            }

            &__card {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                background: var(--white);
                border-radius: 40px;
                padding: ac(40px, 25px) ac(30px, 15px) ac(36px, 25px);
                position: relative;
                z-index: 2;
                max-width: 397px;
                width: 100%;
            }

            &__name, &__subtitle {
                line-height: 130%;
                color: var(--tuna);
            }

            &__name {
                font-weight: 700;
                font-size: ac(37px, 28px);
                margin-bottom: 18px;
            }

            &__subtitle {
                font-weight: 600;
                font-size: ac(27px, 18px);
                margin-bottom: ac(20px, 15px);
            }

            &__contacts {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &__link {
                font-size: ac(21px, 18px);
                margin-bottom: 12px;
                color: var(--tuna);

                &:hover {
                    color: var(--blue);
                }
            }

            &__box {
                max-width: 603px;
                position: relative;
                z-index: 1;
            }
            
        }
    }

    &--org {
        position: relative;
        padding: ac(72px, 60px) 0 0;
        background-color: transparent;

        .container {
            display: flex;
            justify-content: center;
        }
        

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 75%;
            background-color: var(--tuna);
        }

        .plyr {
            &__control--overlaid {
                width: 54px !important;
                height: 33px;
        
                &::before {
                    margin-left: 4px;
                    width: 14px;
                    height: 18px;
                }
            }
        }

        .team-video {
            &__wrapper {
                position: relative;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                max-width: 1015px;
                margin-bottom: -35px;
            }

            &__video {
                position: relative;
                width: calc(100% / 2 - 8px);
                margin-bottom: 35px;

                h3 {
                    margin-bottom: ac(27px, 15px);
                }
            }

            &__decor {
                position: absolute;
        
                &:nth-child(1) {
                    width: ac(118px, 80px, 320, 1440);
                    height: ac(236px, 160px, 320, 1440);
                    left: ac(-56px, -20px, 320, 1440);
                    top: ac(173px, 120px);
                }
        
                &:nth-child(2) {
                    width: ac(236px, 180px, 320, 1440);
                    height: ac(236px, 180px, 320, 1440);
                    right: ac(-92px, -30px, 320, 1440);
                    top: ac(35px, 56px, 320, 1440);
                }
            }
        }
    }

    &__content {
        max-width: 810px;
        margin: 0 auto;
    }

    &__title {
        font-size: ac(53px, 30px, 768, 1440);
	    line-height: ac(64px, 40px, 768, 1440);
        margin-bottom: ac(48px, 30px);
    }

    &__decor {
        position: absolute;
        right: ac(-71px, -30px, 320, 1440);
        top: ac(-61px, -16px, 1024, 1440);
        width: ac(294px, 180px, 320, 1440);
        height: ac(294px, 180px, 320, 1440);

        img {
            object-fit: contain;
        }
    }


    @mixin tab {
        &--org {
            .team-video__wrapper {
                max-width: 700px;
            }

            .team-video__video {
                width: 100%;
            }
        }

        &--prof {
            .team-video__wrapper {
                flex-direction: column-reverse;
            }

            .team-video__box {
                max-width: unset;
            }
        }
    }

    @mixin mob-sm {
        &__title {
            font-size: 28px;
		    line-height: 36px;
        }
    }
}