.map {
    $gap: 0;
    margin-top: $gap;
    position: relative;
    padding-top: ac(151px, 60px);

    &__col {
        width: 70%;
        max-width: 809px;
        position: relative;
        z-index: 1;
        pointer-events: none;
    }

    &__title {
        margin-bottom: 40px;
        pointer-events: all;
        max-width: 92%;
    }

    &__subtitle {
        font-size: ac(32px, 22px);
        line-height: 1.2;
        font-weight: 600;
        margin-bottom: 40px;
        max-width: ac(603px, 500px);
        width: 80%;
        pointer-events: all;
    }

    &__btn-box {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-bottom: 60px;
        pointer-events: all;
    }

    &__slider-box {
        pointer-events: all;
        min-height: ac(413px, 100px, 320, 1440);
    }

    &__slider {
        margin-top: 20px;

        .positions__card {
            height: 100%;
            width: 100%;
        }

        .swiper-slide {
            height: auto;
        }
    }

    &__wrapper {
        position: absolute;
        right: -54px;
        top: 105px;

        @mixin min-media 1920 {
            right: 5vw;
        }

        @mixin min-media 2120 {
            right: 10vw;
        }

        @mixin min-media 2400 {
            right: 15vw;
        }
    }

    &__container {
        width: 664px;
        height: 1060px;

        svg {
            height: ac(1430px, 880px, 993, 1440);;
            left: ac(-800px, -250px, 993, 1440);
            -o-object-fit: contain;
            object-fit: contain;
            position: absolute;
            top: ac(-513px, -113px, 993, 1440);
            width: ac(2152px, 1300px, 993, 1440);
        }

        .marker {
            cursor: pointer;
            clip-path: url(#map-pin-clip-path);
            transition: opacity .3s ease;
        }

        &.active {
            .marker {
                opacity: 0.4;

                &.active {
                    opacity: 1;
                }

                &.is-slider {
                    opacity: 1;
                }
            }
        }
    }

    .map-hint {
        position: absolute;
        max-width: 500px;
        visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s;
        text-align: center;
        font-size: ac(25px, 18px);
        font-weight: 600;
    
        &.active {
            opacity: 1;
            visibility: visible;
        }
    }

    @mixin tab {
        &__wrapper {
            position: relative;
            right: 0;
            top: 60px;

            @mixin mob-sm {
                top: 50px;
            }
        }

        &__container {
            width: 100%;
            height: ac(900px, 400px, 320, 992);

            svg {
                top: ac(-565px, -190px, 320, 993);
                left: ac(-600px, -356px, 320, 993);
                width: ac(2300px, 1000px, 320, 993);
                height: ac(1500px, 700px, 320, 993);
            }
        }

        &__col {
            width: 100%;
            max-width: unset;
        }
    
    
        &__slider-box {
            .swiper {
                overflow: visible;
            }
        }
    }
    
    
    @mixin tab-sm {
        &__title {
            max-width: 100%;
        }

        &__subtitle {
            width: 100%;
        }
    
    }
}



