.reviews {
	$gap: ac(150px, 100px);
	margin-top: $gap;
}

.reviews--prof {
	$gap: 0;
	margin-top: $gap;
}

.reviews--org {
	$gap: ac(150px, 100px);
	margin-top: $gap;
}

.reviews {
	position: relative;
	padding: ac(105px, 60px, 1024, 1440) 0 ac(180px, 70px, 1024, 1440);
	z-index: 1;
	background-color: var(--alice);
	border-radius: 240px 342px 342px 240px;

	&--prof {
		background-color: var(--tuna);
		border-radius: 0;

		.reviews__descr {
			.simplebar-content {
				&>div {
					&,& > * {
						color: var(--white);
					}
				}
			}
		}

		.reviews__nav {
			.arrow-btn {
				&::before {
					background-color: var(--white);
				}

				span {
					color: var(--tuna);
				}
			}
		}
	}

	&__bg {
		height: ac(570px, 300px, 1024, 1440);
		width: ac(570px, 300px, 1024, 1440);
		position: absolute;
		right: ac(-262px, -145px, 1024, 1440);
		top: calc(50% + 20px);
		transform: translateY(-50%);
		z-index: -1;

		img {
			object-fit: contain;
		}
	}

	&__wrapper {
		display: flex;
		flex-wrap: wrap;
	}

	&__title {
		position: relative;
		color: var(--white);
		margin-right: ac(433px, 20px);
		z-index: 2;
	}

	&__rate {
		width: ac(191px, 108px);
		height: ac(29px, 16px);
		margin-top: 36px;

		img {
			object-fit: contain;
		}
	}

	&__slider {
		margin-top: 25px;
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	.reviews__thumb,
	.reviews__text {
		width: 100% !important;
		height: 100%;
	}

	&__thumb-box,
	&__text-box {
		height: 314px;
	}

	&__thumb-box {
		position: relative;
		flex: 0 0 auto;
		min-width: ac(380px, 320px, 1024, 1440);
		margin-right: ac(137px, 20px, 1024, 1440);
		z-index: 1;

		&::before {
			content: '';
			position: absolute;
			top: ac(-193px, -145px, 1024, 1440);
			left: ac(-185px, -135px, 1024, 1440);
			width: ac(685px, 529px, 1024, 1440);
			height: ac(685px, 529px, 1024, 1440);
			background-color: var(--blue);
			border-radius: 100%;
			z-index: -1;
		}

		.swiper-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease;

			&:hover,
			&-thumb-active {
				opacity: 1;
				cursor: pointer;
			}
		}
	}

	&__text-box {
		width: 100%;
		max-width: ac(505px, 400px, 1024, 1440);

		.swiper-slide {
			display: flex;
			flex-direction: column;
			padding-top: 37px;
			
			& > span {
				&:first-child {
					flex: 0 0 auto;
					width: 40px;
					height: 20px;

					img {
						object-fit: contain;
					}
				}
			}
		}
	}

	&__nav {
		flex: 0 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		margin-right: ac(56px, 20px, 1024);

		.arrow-btn {
			position: relative;
			margin: 10px 0;
			background-color: transparent;
			width: 48px;
			height: 48px;
			font-size: 22px;

			span {
				line-height: 48px;
				color: var(--white);
				z-index: 1;
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: var(--tuna);
				border-radius: 100%;
				transition: .3s ease;
			}

			&:hover {
				&::before {
					background-color: var(--blue);
				}
			}
		}
	}

	&__card {
		display: flex;
		align-items: center;
		width: 100%;
		max-width: 390px;
	}

	&__ava {
		flex: 0 0 auto;
		width: 88px;
		height: 88px;
		margin-right: 15px;
		border-radius: 100%;
		overflow: hidden;
	}

	&__name {
		font-weight: 600;
		font-size: ac(21px, 16px);
		line-height: ac(24px, 19px);
		color: var(--white);
	}

	&__company {
		margin-top: 7px;
		font-size: ac(17px, 14px);
		line-height: ac(22px, 20px);
		color: var(--white);
	}

	&__descr {
		margin-top: 20px;

		& > div {
			padding-right: 20px;
			width: calc(100% + 20px);
			max-height: calc(ac(26px, 22px) * 8);
		}

		.simplebar-content {
			&>div {
				padding-right: 10px;

				&,& > * {
					color: var(--tuna);
					font-size: ac(21px, 16px);
					line-height: ac(26px, 22px);

					&:not(:last-child) {
						margin-bottom: ac(30px, 21px);
					}
				}
			}
		}

		.simplebar-track.simplebar-vertical {
			right: 16px;
		}

		.simplebar-scrollbar::before {
			width: 5px;
			background-color: var(--golden);
		}

		.simplebar-vertical {
			width: 9px;
		}
	}

	@mixin tab {
		padding: 45px 0;
		border-radius: 30px;
		overflow: hidden;

		&::before {
			width: 100%;
		}

		&__rate {
			margin-left: auto;
			margin-top: ac(18px, 14px, 320, 1023);
		}

		&__slider {
			flex-direction: column;
			margin-top: ac(40px, 20px);
		}

		&--prof {
			padding-top: 0;
			border-radius: 0;
		}

		&__thumb-box,
		&__text-box,
		&__nav {
			height: auto;
		}

		&__text-box {
			max-width: 100%;
		}

		&__thumb-box {
			width: 100%;
			margin-right: 0;
			min-width: unset;
	
			&::before {
				top: ac(-20px, -10px);
				left: ac(-20px, -10px);
				width: calc(100% + ac(40px, 20px));
				height: calc(100% + ac(40px, 20px));
				transform: rotate(0);
				border-radius: 30px;
			}
		}

		&__nav {
			flex-direction: row;
			margin: 30px 0 -26px auto;
			z-index: 2;

			.arrow-btn {
				width: 37px;
				height: 37px;
				margin: 0 0 0 10px;

				span {
					line-height: 37px;
				}
			}
		}

		&__ava {
			width: ac(88px, 60px, 375, 1024);
			height: ac(88px, 60px, 375, 1024);
			margin-right: 20px;
		}

		&__descr {
			margin-top: 28px;
		}

		&__title {
			color: var(--tuna);
		}

		&__bg {
			display: none;
		}
	}

	@mixin tab-sm {
		&__wrapper {
			padding: 0;
		}

		&__bg {
			display: none;
		}
	}
}
