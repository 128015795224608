.benefits {
    $gap: ac(150px, 80px);
    padding-top: $gap;

    &--org {
        .benefits {
            &__img {
                width: ac(412px, 290px);
                height: ac(412px, 290px);
                margin-right: ac(104px, 45px, 1024, 1440);
                margin-left: 0;
                margin-top: ac(110px, 30px);
            }

            &__title {
                margin-bottom: ac(40px, 25px);
            }

            &__name {
                margin-bottom: ac(21px, 10px);
            }

            &__list {
                margin-bottom: ac(-30px, -25px);
            }

            &__item {
                margin-bottom: ac(30px, 25px);
            }
        }
    }

    &__wrapper {
        display: flex;
    }

    &__img {
        margin-top: ac(70px, 30px);
        margin-left: ac(-109px, -60px);
        flex-shrink: 0;
        width: ac(580px, 290px);
        height: ac(580px, 290px);
        margin-right: 45px;

        img {
            border-radius: 100%
        }
    }

    &__col {
        position: relative;
        width: 100%;
    }

    &__title {
        max-width: 603px;
        margin-bottom: ac(40px, 20px);
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        margin-right: ac(-118px, -30px, 1024, 1440);
        margin-bottom: ac(-40px, -25px);
    }

    &__item {
        width: calc(100% / 2 - ac(118px, 30px, 1024, 1440));
        margin-right: ac(118px, 30px, 1024, 1440);
        margin-bottom: ac(40px, 25px);
    }

    &__icon {
        max-width: 69px;
        height: 38px;
        margin-bottom: ac(15px, 10px);

        img {
            object-fit: contain;
            object-position: left;
        }
    }

    &__name {
        font-weight: 600;
        font-size: ac(27px, 20px);
        line-height: 121%;
        margin-bottom: ac(21px, 15px);
    }

    &__desc {
        &[data-simplebar] {
            max-height: 178px;
            padding-right: 10px;
            margin-right: -10px;
        }

        .simplebar-vertical {
			width: 5px;
			background-color: rgb(5, 142, 217, .2);
		}

		.simplebar-scrollbar::before {
			background-color: var(--blue);
		}
    }

    @mixin tab {
        &__wrapper {
            flex-direction: column;
        }

        &__img {
            margin: 0 auto 25px;
        }

        &__item, &__title {
            max-width: 100%;
        }

        &--org {
            .benefits__img {
                margin: 0 auto 25px;
            }
        }
    }

    @mixin mob-lg {
        &__item {
            width: calc(100% - ac(118px, 20px, 1024, 1440));
        }
    }
}