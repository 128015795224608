.actions {
    $gap: ac(150px, 99px);
    margin-top: $gap;

    &__wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: -15px;
        margin-right: -15px;
    }

    &__card {
        padding: ac(40px, 20px) ac(30px, 15px);
        background: var(--tuna);
        color: var(--white);
        max-width: 294px;
        margin-right: 15px;
        margin-bottom: 15px;
        border-radius: ac(40px, 30px);

        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .btn {
            margin-top: auto;
        }
    }

    &__title {
        margin-bottom: ac(21px, 15px);
    }

    &__desc {
        margin-bottom: ac(20px, 15px);
        font-weight: 600;
        font-size: ac(27px, 20px);
        line-height: ac(32px, 26px);
    }

    @mixin tab {
        &__wrap {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        &__card {
            max-width: 100%;
        }
    }

    @mixin mob-xl {
        &__wrap {
            grid-template-columns: 1fr;
        }
    }
}