@import 'https://fonts.googleapis.com/css2?family=Cabin:wght@300;400;500;600;700&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Figtree:wght@300;400;500;600;700&display=swap';

:root {
	--font-main: "Cabin", sans-serif;
	--font-sec: "Figtree", sans-serif;
	--blue: rgb(5, 142, 217);
	--golden: rgb(249, 218, 65);
	--tuna: #222222;
	--alice: #E1F4FF;
	--white: #ffffff;
	--bg-color: 34, 34, 34;
	--bg-color-opacity: 34, 34, 34, 0;
	--red: #D40059;

	--none: 0;
	--main: ac(150px, 100px);
	--sm: ac(45px, 25px);
}
