.headlines {
    $gap: ac(130px, 70px);
    margin-top: $gap;

    .container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }


    h2  {
        margin-top: 20px;
        margin-right: 5px;
    }

    &__nav {
        margin-top: 20px;
        margin-left: auto;
        user-select: none;
        align-self: flex-end;

        .arrow-btn {
            transform: rotate(15deg);
            width: ac(48px, 37px);
            height: ac(48px, 37px);

            &:nth-child(1) {
                margin-right: ac(15px, 5px);
            }

            span {
                line-height: ac(48px, 37px);
                font-size: ac(28px, 22px);
                transform: rotate(-15deg);
            }
        }
    }

    &__slider {
		margin-top: ac(40px, 25px);
		width: 100%;
		display: flex;
		align-items: flex-start;
	}

	&__thumb,
	&__video {
		width: 100% !important;
		height: 100%;
	}

    &__thumb-box,
	&__video-box {
		height: 516px;
	}

    &__video-box {
		width: 100%;
        max-width: ac(603px, 380px, 1024, 1440);
        flex: 0 0 auto;
        margin-right: 15px;

		.swiper-slide {
			display: flex;
			flex-direction: column;

            .plyr {
                video.is-hide {
                    opacity: 0;
                }
            }

            .plyr__video-wrapper {
                z-index: -1 !important;
            }

            &>*:first-child {
                border-radius: ac(40px, 30px) ac(40px, 30px) 0 0;
            }

            iframe {
                width: 100%;
                height: ac(315px, 225px);
            }
        }

        .plyr__control.plyr__control--overlaid {
            width: ac(75px, 65px) !important;
            height: ac(46px, 40px);

            &::before {
                width: 17px;
            }
        }

        .headlines__text-box {
            padding: ac(31px, 15px) ac(31px, 15px) ac(47px, 15px);
            background-color: var(--alice);
            border-radius: 0 0 ac(40px, 30px) ac(40px, 30px);
        }

        .headlines__descr {
            &>p:first-child {
                max-height: 88px;
                @mixin max-line-length 4;
            }
        }
	}

    &__thumb-box {
        width: 100%;

		.swiper-slide {
			opacity: 0.5;
			transition: opacity 0.3s ease;

            iframe {
                width: 100%;
                height: 100%;
                cursor: pointer;
                pointer-events: none;
            }

			&:hover,
			&-thumb-active {
				opacity: 1;
				cursor: pointer;
			}
		}

        .headlines__title {
            max-height: 24px;
            @mixin max-line-length 1;
        }

        .plyr__control.plyr__control--overlaid {
            width: ac(52px, 45px) !important;
            height: ac(32px, 28px);

            &::before {
                width: 17px;
            }
        }

        .headlines__descr {
            margin-top: 10px;

            &>p:first-child {
                max-height: 88px;
                @mixin max-line-length 4;
            }

            &>* {
                font-weight: 300;
                font-size: 16px;
                line-height: 22px;
            }
        }
	}

    &__card {
        display: flex;
        background-color: var(--alice);
        border-radius: ac(40px, 30px);
        overflow: hidden;
    }

    &__text-box {
        position: relative;
        width: 100%;
        padding: ac(20px, 15px) ac(22px, 15px);

        .arrow-btn {
            position: absolute;
            right: 9px;
            top: 9px;
            width: 29px;
            height: 29px;

            span {
                line-height: 29px;
                font-size: 14px;
            }
        }
    }

    &__title {
        max-width: calc(100% - 40px);
        font-weight: 600;
        font-size: ac(21px, 17px);
        line-height: ac(26px, 20px);
    }

    &__descr {
        margin-top: 7px;
        max-height: 88px;

        &, &>* {
            font-weight: 400;
        }

        .simplebar-track.simplebar-vertical {
            width: 4px;
            right: -4px;
        }

        .simplebar-scrollbar::before {
            width: 4px;
            background-color: var(--tuna);
        }
    }

    &__img {
        position: relative;
        flex: 0 0 auto;
        width: 206px;
        height: 162px;

        &::before, &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        &::after {
            content: '';
            width: 53px;
            height: 33px;
            border-radius: 5px;
            background-color: var(--blue);
        }

        &::before {
            content: "\e90c";
            font-family: "icomoon" !important;
            color: var(--white);
            font-size: 15px;
            line-height: 33px;
            margin-left: 2px;
            z-index: 1;
        }
    }

    
    @mixin min-media 1024 {
        &__thumb-box {
            max-width: calc(100% - ac(603px, 380px, 1024, 1440) - 15px);
        }
    }

    @mixin tab {
		&__slider {
			flex-direction: column;
		}

        &__video-box {
            margin: 0 auto 20px;
            max-width: 600px;

        }

		&__thumb-box {
			width: 100%;
            display: flex;

            .swiper-slide {
                height: auto;
            }
		}

		&__thumb-box,
		&__video-box {
			height: auto;
		}

        &__card {
            flex-direction: column-reverse;
            height: 100%;
        }

        &__text-box {
            margin-bottom: auto;
        }

        &__img {
            width: 100%;
        }
	}

    @mixin media 500 {
        &__img {
            height: 100px;
        }

        &__thumb-box {
            .headlines__text-box {
                display: none;
            }
        }
    }
}
