.awards {
	$gap: ac(160px, 80px);
	margin-top: $gap;
}

.awards--org {
	$gap: ac(153px, 80px);
	margin-top: $gap;
}

.awards {
	position: relative;
	z-index: 2;

	&__title {
		text-align: center;
		margin-bottom: 35px;
	}

	.slider-nav {
		margin-top: 20px;
		justify-content: center;
		width: 100%;
		z-index: inherit;
	}

	&__wrapper {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}

	&__slider {
		overflow: visible !important;
	}

	&__slide {
		&:hover {
			z-index: 1;
		}
	}

	&__logo {
		flex: 0 0 auto;
		position: relative;
		width: 100%;

		img {
			object-fit: contain;
			height: ac(112px, 103px);
		}

		&:hover {
			.awards__logo-desc {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	&__logo-desc {
		position: absolute;
		width: ac(294px, 260px);
		background: var(--white);
		padding: ac(15px, 10px);
		border: 1px solid var(--tuna);
		border-radius: 7px;
		z-index: 10;
		transition: 0.3s ease;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
	}

	&__descr {
		font-weight: 600;
		margin-top: 18px;
		line-height: 19px;
	}

	@mixin mob-lg {
		.awards__logo {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.awards__logo-title {
			text-align: center;
		}
	}
}
