.banner {
	$gap: ac(152px, 80px);
	margin-top: $gap;

	&__wrapper {
		position: relative;
		padding: ac(40px, 90px, 900, 1440) ac(30px, 20px) ac(51px, 40px);
		display: flex;
		flex-wrap: wrap;
		align-items: flex-end;
		background-color: var(--tuna);
		border-radius: 40px;
		overflow: hidden;
	}

	&__bg {
		position: absolute;
		right: ac(-5px, -65px, 900, 1440);
		top: ac(-52px, -39px, 900, 1440);
		width: ac(409px, 224px, 900, 1440);
		height: ac(340px, 210px, 900, 1440);

		img {
			object-fit: contain;
		}
	}

	&__subtitle,
	&__title {
		position: relative;
		color: var(--white);
		z-index: 1;
	}

	&__title {
		width: 100%;
		margin-bottom: ac(32px, 15px);
	}

	&__subtitle {
		margin-right: auto;
		width: calc(100% - 250px);
		max-width: 825px;
		font-weight: 600;
		font-size: ac(27px, 20px);
		line-height: ac(33px, 24px);
	}

	&__btn {
		margin-bottom: ac(11px, 0px);
		z-index: 1;
	}

	@mixin mob-xl {
		&__subtitle {
			width: 100%;
		}

		&__btn {
			margin-top: 25px;
			margin-bottom: 0;
		}
	}
}
