.questions {
    $gap: ac(147px, 80px);
    margin-top: $gap;
}

.questions--org {
    $gap: ac(138px, 80px);
    margin-top: $gap;
}

.questions {
    position: relative;

    &__title {
        text-align: center;
        font-size: ac(50px, 30px);
        margin-bottom: ac(38px, 25px);
        font-family: var(--font-sec);
    }

    &__accordions {
        max-width: 810px;
        margin: 0 auto 50px;
        display: flex;
        flex-direction: column;
    }

    .btn {
        display: flex;
        width: fit-content;
        margin: 0 auto;
    }

    &__decor {
        position: absolute;

        &:nth-child(1) {
            width: ac(236px, 140px);
            height: ac(354px, 250px);
            left: -38px;
            top: ac(25px, 100px);
        }

        &:nth-child(2) {
            width: ac(236px, 100px);
            height: ac(236px, 150px);
            right: ac(-62px, -30px);
            top: ac(311px, 0px);
        }

        img {
            object-fit: contain;
        }
    }

}