.possibilities {
    $gap: ac(231px, 80px);
    margin-top: $gap;
}

.possibilities--nurs {
    $gap: ac(303px, 80px);
    margin-top: $gap;
}

.possibilities--team {
    $gap: ac(223px, 80px);
    margin-top: $gap;
}

.possibilities {
    position: relative;
    background-color: var(--alice);
    border-radius: ac(393px, 30px);

    &--team {
        .possibilities__col {
            margin-left: ac(210px, 25px, 768, 1440);
        }

        .possibilities__img {
            max-width: ac(447px, 350px, 1024, 1440);
            height: ac(447px, 350px, 1024, 1440);
        }
    }

    &__wrapper {
        position: relative;
        display: flex;
    }

    &__img {
        margin-left: ac(-141px, -50px, 1024, 1440);
        margin-top: ac(-72px, -50px, 1024, 1440);
        flex-shrink: 0;
        width: 100%;
        max-width: ac(507px, 380px, 1024, 1440);
        height: ac(507px, 380px, 1024, 1440);

        img {
            border-radius: 100%;
            object-position: center top;
        }
    }

    &__col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 40px 0;
        max-width: ac(603px, 400px, 768, 1440);
        margin-left: ac(150px, 25px, 768, 1440);
    }

    &__title {
        margin-bottom: ac(39px, 25px);
    }

    &__subtitle {
        margin-bottom: ac(19px, 15px);
        font-weight: 600;
        font-size: ac(27px, 18px);
        line-height: ac(34px, 22px);
    }

    @mixin tab {
        border-radius: 30px;

        &__wrapper {
            flex-direction: column;
        }

        &__col {
            max-width: 100%;
            margin-left: 0;
        }

        &__img {
            margin: ac(-50px, -20px) auto 0;
            max-width: ac(420px, 290px, 320, 1024);
            height: ac(420px, 290px, 320, 1024);
        }

        &--team {
            .possibilities__col {
                margin-left: 0;
            }
    
            .possibilities__img {
                max-width: ac(420px, 290px, 320, 1024);
                height: ac(420px, 290px, 320, 1024);
            }
        }
    }
}