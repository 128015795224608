.topics {
	$gap: ac(117px, 70px);
	margin-top: $gap;

	#resources_search_result {
		&>.topics__grid:first-of-type {
			margin-top: 0;
		}
	}

	&__title-box {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-bottom: ac(64px, 30px);

		&>* {
			margin-top: 15px;
		}
	}

	&__title {
		margin-right: ac(142px, 20px, 1024, 1440);
		margin-bottom: 10px;
	}

	&__search {
		position: relative;
		max-width: 397px;
		width: 100%;
	}

	&__input {
		width: 100%;
		padding: 7px 60px 7px ac(30px, 15px);
		background: var(--white);
		border: 1px solid var(--golden);
		border-radius: 31px;
		line-height: ac(46px, 35px);
	}

	&__btn {
		position: absolute;
		right: 7px;
		top: 7px;
		width: ac(48px, 37px);
		height: ac(48px, 37px);

		.icon-arrow {
			line-height: ac(48px, 37px);
			font-size: ac(21px, 18px);
		}
	}

	&__grid {
		&:first-of-type {
			margin-top: 66px;
		}
	}

	&__head {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: ac(39px, 20px) ac(28px, 15px);
		min-height: ac(263px, 220px, 770, 1440);
		cursor: pointer;
		border: 1px solid var(--tuna);
		background-color: var(--white);
		transition: .3s ease;
		border-radius: 40px;

		p {
			font-weight: 700;
			font-size: ac(37px, 20px);
			line-height: ac(45px, 32px);
			margin-bottom: auto;
		}

		.btn {
			&>span {
				transition: .3s ease;

				&:nth-child(2) {
					opacity: 0;
				}
			}
		}

		&:hover, &.open {
			background-color: var(--tuna);
			color: var(--white);

			&::before {
				background-color: var(--tuna);
			}

			.btn {
				background-color: var(--golden);
				color: var(--tuna);

				.arrow-btn {
					span {
						color: var(--tuna);
					}
				}
			}
		}

		&.open {
			.btn {
				&>span {
					&:nth-child(1) {
						opacity: 0;
						margin-right: 5px;
					}

					&:nth-child(2) {
						opacity: 1;
					}
				}
			}
		}
	}

	&__content {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
		transition: max-height .5s ease;

		&.open {
            max-height: 7000px;
			opacity: 1;
			transition: .5s ease;
		}

		&>div {
			padding-top: ac(37px, 30px);

			&:last-child {
				padding-bottom: ac(150px, 30px);
			}


			h4, h5, h6 {
				font-weight: 600;
				max-width: 809px;
				
				&:not(:first-child) {
					margin-top: .6em;
				}
				
				&:not(:last-child) {
					margin-bottom: 0.55em;
				}
			}
	
			h4 {
				font-size: ac(32px, 22px);
				line-height: ac(38px, 28px);

				&.mt {
					margin-top: 1.6em !important;
					margin-bottom: 1.2em !important;
				}
				
				&:not(:first-child) {
					margin-top: ac(40px, 25px);
				}

				&:not(:last-child) {
					margin-bottom: ac(20px, 15px);
				}

				&+h5 {
					margin-top: 0 !important;
				}
			}
	
			h5 {
				font-size: ac(27px, 20px);
				line-height: ac(34px, 26px);

				&:not(:first-child) {
					margin-top: ac(39px, 25px);
				}

				&:not(:last-child) {
					margin-bottom: ac(20px, 15px);
				}

				&+.topics__accardion {
					margin-top: ac(25px, 20px);
				}
			}
	
			h6 {
				&:not(:first-child) {
					margin-top: ac(29px, 20px);
				}

				&:not(:last-child) {
					margin-bottom: ac(19px, 15px);
				}
				font-size: ac(21px, 18px);
				line-height: ac(26px, 24px);
			}

			p:not(.topics__download-title) {
				max-width: 1015px;

				img {
					height: auto !important;
					object-fit: contain;
				}
			}
	
			ul {
				margin-top: .5em;
				list-style: none;
				padding-left: ac(25px, 15px);
				max-width: 706px;

				&:not(:last-child) {
					margin-bottom: 40px;
				}

				li {
					position: relative;

					&::before {
						content: '';
						position: absolute;
						left: -14px;
						top: ac(9px, 7px);
						border-radius: 100%;
						width: 4px;
						height: 4px;
						background-color: var(--tuna);
					}

					&:not(:last-child) {
						margin-bottom: 3px;
					}
				}
			}
	
			&>p {
				&:not(:last-child) {
					margin-bottom: 10px;
				}
				max-width: 912px;
			}
	
			.btn {
				margin-top: ac(21px, 15px);
			}
	
			a:not(.btn, .topics__download) {
				position: relative;
				color: var(--blue);
				font-size: inherit;
	
				&::before {
					content: '';
					position: absolute;
					width: 100%;
					height: 1px;
					left: 0;
					bottom: 4px;
					background-color: var(--blue);
					transition: .3s ease;
				}
	
				&:hover {
					color: var(--golden);
	
					&::before {
						background-color: var(--golden);
					}
				}
			}
		}
	}

	&__tabs {
		display: flex;
		flex-wrap: wrap;
		padding-top: ac(37px, 20px);
		margin-top: -15px;
		margin-right: ac(-91px, -15px);
		margin-bottom: ac(52px, 45px);
	}

	&__tab {
		position: relative;
		font-weight: 600;
		font-size: ac(27px, 18px);
		line-height: ac(36px, 24px);
		margin-right: ac(91px, 15px);
		margin-top: 15px;
		transition: .3s ease;
		user-select: none;
		cursor: pointer;
		
		&::before {
			content: '';
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 0%;
			height: 2px;
			background-color: var(--blue);
			transition: .3s ease;
		}

		&:hover, &.active {
			color: var(--blue);
		}

		&.active {
			&::before {
				width: 100%;
			}
		}
	}

    &__tab-content {
        display: none;

        &.active {
            display: block;
        }

		&>.topics__accardion:first-child {
			margin-top: -11px;
		}
    }

    &__accardion {
		border: 1px solid var(--tuna);
		border-radius: ac(45px, 30px);
		transition: .3s ease !important;
		background: var(--white);

		&:not(:last-child) {
			margin-bottom: 15px;
		}

		&:hover {
			border: 1px solid var(--blue);
		}

		&+h5 {
			margin-top: ac(40px, 25px);
		}
    }

    &__accardion-head {
		display: flex;
		justify-content: space-between;
		cursor: pointer;
		padding: ac(27px, 15px) ac(30px, 15px);
		transition: .3s ease;

		&:hover {
			&>span {
				color: var(--blue);
			}

			.arrow-btn {
				background-color: var(--blue);
			}
		}

		&>span:not(.arrow-btn) {
			align-self: center;
			font-size: ac(27px, 18px);
			line-height: ac(34px, 20px);
			font-weight: 600;
			transition: .3s ease;

			&:first-child {
				margin-right: 20px;
			}
		}

		.arrow-btn {
			margin-top: ac(3px, 0px);
			background-color: var(--golden);
			height: 29px;
			width: 29px;
			transform: rotate(90deg);

			span {
				color: var(--tuna);
				font-size: 15px;
				line-height: 29px;
			}
		}

		&.open {
			padding-bottom: 20px;

			.arrow-btn {
				background-color: var(--blue);
				transform: rotate(0deg);
			}
		}
    }

    &__accardion-content {
        max-height: 0;
        overflow: hidden;
        padding: 0 !important;
        transition: max-height 0.5s ease;
		max-width: 1130px;
	
		&>div {
			padding: 0px ac(30px, 15px) ac(30px, 15px);
	
			&>p {
				margin-bottom: 10px;
			}
		}

		&.open {
			max-height: 1000px;
		}
    }

	&__download-box {
		display: flex;
		flex-wrap: wrap;
		margin-right: -15px;
		row-gap: 15px;
	}

	&__download {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		width: calc(100% / 3 - 15px);
		margin-right: 15px;
		background-color: var(--golden);
		border-radius: 40px;
		padding: ac(40px, 20px) ac(30px, 15px);
		min-height: ac(209px, 170px);
		transition: .3s ease;

		&:hover {
			background-color: var(--tuna);

			.topics__download-title {
				color: var(--white)
			}

			.btn {
				background-color: var(--golden);
	
				.arrow-btn {
					background-color: var(--tuna);

					span {
						color: var(--white);
					}
				}
			}
		}

		.btn {
			margin-top: auto !important;
			border: 0;
			pointer-events: none;

			.arrow-btn {
				transform: rotate(135deg);
				background-color: var(--golden);
			}
		}
	}

	&__download-title {
		font-size: ac(27px, 20px);
		line-height: ac(33px, 24px);
		font-weight: 600;
		margin-bottom: 10px;
		transition: .3s ease;
	}

	&__text-col {
		margin-top: ac(39px, 20px);

		p {
			&:not(:first-child) {
				margin-top: 10px;
			}
		}
	}

	@media (min-width: 770px) {
		&__grid {
			display: grid;
			grid-template-areas:
				"item item item"
				"desc desc desc";
			grid-template-columns: 1fr 1fr 1fr;
			gap: 15px;

			&:last-child {
				.topics__content.open  {
					margin-bottom: 15px;
				}

				.topics__content>div {
					padding-bottom: 0;
				}
			}
		}

		&__content {
			grid-area: desc;
		}
	}

	@mixin tab {
		&__download {
			width: calc(100% / 2 - 15px);
		}

		&__text-col {
			&>*:not(:first-child) {
				margin-top: 20px;
			}
		}
	}

	@mixin tab-sm {
		&__grid {
			&:not(:first-child) {
				margin-top: 15px;
			}
		}

		&__head {
			min-height: 180px;

			&:not(:first-child) {
				margin-top: 15px;
			}
		}
	}

	@mixin mob-lg {
		&__download {
			width: calc(100% - 15px);
		}
	}

	@mixin mob {
		&__text-col {
			.btn {
				font-size: 12px;
			}
		}
	}
}
