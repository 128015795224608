.offices {
    $gap: ac(149px, 80px);
    margin-top: $gap;

    &__title {
        margin-bottom: ac(39px, 25px);
        margin-left: ac(4px, 0px);
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: ac(15px, 20px);
        grid-row-gap: ac(39px, 20px);
    
        li {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &:nth-child(4), &:nth-child(5), &:nth-child(6) {
                h3 {
                    margin-bottom: ac(30px, 15px, 1024, 1440);
                }
            }
        }
    
        h3 {
            font-size: ac(32px, 22px);
            border-bottom: 2px solid var(--blue);
            display: inline-block;
            align-self: flex-start;
            min-width: 168px;
            margin-bottom: ac(25px, 15px, 1024, 1440);
            font-weight: 600;
        }
    
        p {
            font-size: ac(21px, 16px);
            line-height: ac(26px, 20px);
            margin-bottom: 9px;
    
            a {
                font-size: inherit;
                margin-bottom: 0;
            }
        }
    
        a {
            margin-bottom: 8px;

            &:hover {
                color: var(--blue);
            }
        }
    
        @media (max-width: 992px) {
            grid-template-columns: 1fr 1fr;
        }
        @media (max-width: 550px) {
            grid-template-columns: 1fr;
    
            br {
                display: none;
            }
        }
    }
}

