.prioritise {
    $gap: ac(124px, 100px);
    margin-top: $gap;
   
    .slider-nav {
        margin-top: 20px;
        justify-content: center;
        width: 100%;
    }

    &__title {
        max-width: 1017px;
        margin: 0 auto ac(60px, 30px);
        text-align: center;
    }

    &__list {
        display: flex;
    }

    &__slider {
        overflow: visible !important;
    }

    &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;

        &::before {
            content: '';
            position: absolute;
            top: ac(-26px, -10px);
            left: 50%;
            width: ac(136px, 100px);
            height: ac(136px, 100px);
            border: ac(35px, 25px) solid var(--alice);
            transform: translateX(-50%);
            border-radius: 100%;
            z-index: -1;
        }
    }
    
    &__icon {
        width: 38px;
        height: 38px;

        img {
            object-fit: contain;
        }
    }

    &__descr {
        font-weight: 600;
        font-size: ac(21px, 18px);
        line-height: 124%;
        margin-top: ac(20px, 15px);
        text-align: center;
    }
}