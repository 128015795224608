html {
    font-size: ac(17px, 16px);
    line-height: ac(22px, 19px);
    font-family: var(--font-main);
    font-weight: 500;
    color: var(--tuna);

    scrollbar-width: thin;
    scrollbar-color: var(--tuna) rgba(30, 30, 30, 0.2);

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(30, 30, 30, 0.2);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--tuna);
        border-radius: 0;
    }
}

body {
    min-width: 320px;
    margin: 0;
    background-color: var(--white);

    &.no-scroll {
        overflow: hidden;
    }
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

.swiper {
    width: 100% !important;
}

.swiper-wrapper {
    left: 0 !important;
}

.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.swiper-button-lock {
    display: none !important;
}

.lazyload,
.lazyloading {
    opacity: 0;
}

.lazyloaded {
    opacity: 1;
    transition: opacity 300ms;
}

#site {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.main {
    flex-grow: 1;
}

.container {
    width: 100%;
    max-width: 1298px;
    margin: 0 auto;
    padding: 0 38px;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.simplebar-content {
    &.active {
        & > * {
            padding-right: 15px;
        }
    }
}

.choices {
    flex-grow: 1;
    margin-bottom: 0;

    &::after {
        display: none;
    }

    .choices__list {
        width: 100%;
        overflow: hidden !important;
        display: flex;
        flex-direction: column;

        &--dropdown {
            z-index: 2;

            .choices__item, .choices__input {
                line-height: 30px;
            }

            .choices__item {
                &::after {
                    display: none;
                }
            }
        }

        & > div {
            padding-right: 20px;
            width: calc(100% + 20px);
            max-height: 200px;
        }

        .simplebar-track.simplebar-vertical {
            width: 9px;
            right: 20px;
        }

        .simplebar-scrollbar::before {
            width: 5px;
            background-color: var(--golden);
            opacity: 1;
        }

    }

    .choices__inner,
    .choices__list--single {
        padding: 0;
        padding-bottom: 0 !important;
    }

    .choices__list--single {
        .choices__item {
            word-break: break-all;
            @mixin max-line-length 1;
        }
    }

    .choices__inner {
        background-color: var(--white);
        border: 0;
    }

    .choices__item--selectable {
        font-size: 17px;
        font-weight: 400;
        line-height: 48px;
        opacity: 1;
    }
}

input, textarea {
    font-weight: 400;
    border-radius: 0;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
        -webkit-text-fill-color: inherit !important;
    }

    &::placeholder {
        color: inherit;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        opacity: 1;
    }
}

textarea{
    resize: none;
    scrollbar-width: thin;
    scrollbar-color: var(--golden) rgba(249, 218, 65, 0.2);
    overflow-x: hidden;
    overflow-y: auto;
    clip-path: inset(0 0 0 0 round 40px);

    &::-webkit-scrollbar {
        width: 4px;
    }

    &::-webkit-scrollbar-track {
        background: rgba(249, 218, 65, 0.2);
        border-radius: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: var(--golden);
        border-radius: 0;
    }
}

.arrow-btn {
    flex: 0 0 auto;
    display: inline-flex;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 100%;
    background-color: var(--tuna);
    transition: 0.3s ease;

    span {
        color: var(--white);
        line-height: 48px;
        transition: 0.3s ease;
    }

    &:hover {
        background-color: var(--blue);
    }
}

.slider-nav {
    display: flex;
    user-select: none;
    z-index: 5;

    .arrow-btn {
        width: ac(48px, 37px);
        height: ac(48px, 37px);

        &:first-child {
            margin-right: ac(20px, 5px);

            span {
                transform: translate(ac(-2px, -1px), ac(1px, 0px));
            }
        }

        &:last-child {
            span {
                transform: translateX(ac(2px, 1px));
            }
        }

        span {
            line-height: ac(48px, 37px);
            font-size: ac(28px, 22px);
        }
    }

    &--wh {
        .arrow-btn {
            background-color: var(--white);

            span {
                color: var(--tuna);
            }

            &:hover {
                background-color: var(--blue);
            }
        }
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    padding: 0px 5px 0px 9px;
    font-size: ac(17px, 15px, 320, 1440);
    line-height: 37px;
    background: var(--white);
    border: 1px solid var(--tuna);
    border-radius: 20px;
    transition: 0.3s ease;
    cursor: pointer;

    & > span {
        &:first-child {
            margin-right: 11px;
            color: var(--tuna);
        }
    }

    .arrow-btn {
        width: 29px;
        height: 29px;
        background-color: var(--blue);

        span {
            line-height: 29px;
            font-size: 15px;
            color: var(--tuna);
        }
    }

    &:hover {
        background-color: var(--golden);

        .arrow-btn {
            span {
                color: var(--white);
            }
        }
    }
}

.simplebar-scrollbar::before {
    opacity: 1;
    top: 0 !important;
    left: 0;
    bottom: 0 !important;
    right: 0;
}

.pagination {
    margin-top: ac(60px, 35px);
    display: flex;
    justify-content: center;

    &__num {
        display: flex;
        justify-content: center;
        width: 33px;
        height: 33px;
        padding-top: 1px;
        font-size: 21px;
        line-height: 32px;
        background-color: var(--alice);
        transition: .3s ease;
        cursor: pointer;
        user-select: none;
        border-radius: 100%;

        &:not(:last-child) {
            margin-right: 1px;
        }

        &:hover, &.active {
            color: var(--white);
            background-color: var(--tuna);
        }
    }
}

.plyr {
    video {
        object-fit: cover;
    }

    &__poster {
        background-size: cover;
    }

    &__control--overlaid {
        display: flex !important;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: ac(88px, 53px) !important;
        height: ac(54px, 32px);
        border-radius: 10px;
        background-color: var(--blue);
        opacity: 1;

        &::before {
            content: '';
            margin-left: 4px;
            width: ac(22px, 16px);
            height: ac(27px, 18px);
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='22' height='27' fill='none'%3E%3Cpath fill='%23fff' d='M22 13.5.25 26.057V.943L22 13.5Z'/%3E%3C/svg%3E") center / contain no-repeat;
        }

        &:hover {
            background-color: var(--tuna) !important;
        }

        svg {
            display: none;
        }
    }

    .plyr__video-wrapper {
        z-index: 1;
    }
}

.plyr--video.plyr--stopped .plyr__controls {
    display: none;
}

.submit-btn {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: ac(27px, 24px);
    flex-shrink: 0;

    span {
        padding-right: 17px;
        transition: color 0.3s;
    }

    i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        border-radius: 100%;
        background: var(--tuna);
        color: var(--white);
        font-size: ac(22px, 18px);
        transition: 0.3s;
    }

    &:hover {
        color: var(--blue) !important;

        i {
            color: var(--white) !important;
            background: var(--blue) !important;
        }
    }

    &.submit-white{
        color: var(--white);

        .icon-arrow{
            background: var(--white);
            color: var(--tuna);
        }
    }
}

.contact-form {
    &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 13px 15px;
    }

    &-caption {
        display: flex;
        align-items: center;
        font-size: ac(30px, 25px);
        margin-bottom: 49px;

        h2 {
            margin-right: 12px;
            line-height: normal;
            font-size: inherit;
        }

        .choices {
            margin-top: 2px;
            flex-grow: 1;
            max-width: 303px;

            .choices__inner {
                min-height: unset;
            }

            .choices__list--dropdown {
                .choices__list {
                    &>div {
                        max-height: 220px;
                    }
                }

                .choices__item {
                    &:first-child {
                        display: none;
                    }
                }

            }
        }

        @mixin mob-lg {
            align-items: flex-start;
            flex-direction: column;

            .choices {
                max-width: 100%;
                width: 100%;
                margin: 10px 0  0!important;
            }
        }
    }

    textarea {
        height: 124px;
        resize: none;
    }

    .submit-btn {
        margin-top: 3px;
    }

    @media (max-width: 1023px) {
        .contact-form-grid {
            grid-template-columns: 1fr;
        }
    }
}

.terms {
    $gap: ac(186px, 130px);
    margin-top: $gap;

    &__wrapper {
        a {
            color: var(--golden);
            word-break: break-all;
            word-wrap: break-word;
            overflow-wrap: break-word;
            max-width: 100%;

            &:hover {
                color: var(--blue);
            }
        }

        h1 {
            margin-bottom: ac(40px, 30px);
        }

        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(20px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(41px, 20px);
            }
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4 {
            font-size: ac(34px, 26px);
            line-height: ac(44px, 36px);
        }

        h5 {
            font-size: ac(32px, 24px);
            line-height: ac(42px, 34px);
        }

        h6 {
            font-size: ac(27px, 22px);
            line-height: ac(32px, 26px);
        }

        p {
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            li {
                position: relative;
                padding-left: 26px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 12px;
                    top: ac(9px, 7px);
                    width: 4px;
                    height: 4px;
                    border-radius: 100%;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                }
            }
        }
    }
}

.page404 {
    position: relative;
    background-color: var(--alice);
    background: linear-gradient(-45deg,  var(--golden), var(--tuna), var(--blue));
    background-size: 400% 400%;
    animation: gradient 20s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%
        }

        50% {
            background-position: 100% 50%
        }

        100% {
            background-position: 0% 50%
        }
    }

    &__wrap {
        position: relative;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        z-index: 2;
    }

    h1 {
        margin-bottom: 0.3em;
        color: var(--white);
    }

    p {
        font-size: ac(22px, 18px);
        line-height: 22px;
        margin-bottom: 2em;
        color: var(--white);
    }
}

#bold-credits {
    width: 150px;
    transition: ease-in-out 0.25s;

    &::before,
    &::after {
        display: none;
    }

    &:hover {
        transform: scale(1.1);
    }
}

.grecaptcha-badge {
    z-index: 1111;
}

.termsfeed-com---pc-dialog {
    .cc-cp-body-tabs {
        background: var(--white) !important;
    }

    #strictly-necessary_label, .is-inactive, .cc-pc-head-close {
        color: var(--tuna) !important;
    }

    .cc-pc-head-close {
        &:hover {
            color: var(--golden) !important;
        }
    }

    .cc-cp-body-tabs-item {
        background: var(--alice) !important;

        button {
            color: var(--tuna) !important;
        }
    }

    .cc-cp-body-tabs-item[active=true] {
        background: var(--white) !important;

        button {
            color: var(--tuna) !important;
            box-shadow: unset !important;
        }
    }

    .cc-cp-foot, .cc-pc-head {
        background: var(--alice) !important;
    }

    .cc-cp-body-content {
        background: var(--white) !important;
    }

    .cc-cp-body-content-entry-title, .cc-cp-body-content-entry-text, .cc-cp-foot-byline, .cc-pc-head-title-text, .cc-pc-head-title-headline {
        color: var(--tuna) !important;

        a {
            color: var(--tuna) !important;
        }
    }

    .cc-cp-foot-save {
        align-items: center;
        display: inline-flex;
        border: 1px solid var(--tuna);
        background-color: var(--white);
        border-radius: 20px;
        margin-bottom: 0 !important;
        padding: 4px 5px 4px 9px;
        transition: .3s ease;
        color: var(--tuna);

        &::after {
            content: '';
            margin-left: 10px;
            border-radius: 7px;
            width: 29px;
            height: 29px;
            transition: .3s ease;
            background-position: center;
            background-size: contain;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%23058ED9' rx='14.5'/%3E%3Cpath stroke='%23080223' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
        }

        &:hover {
            background-color: var(--golden);
            color: var(--tuna);

            &::after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%23058ED9' rx='14.5'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                transform: unset;
            }
        }
    }

    @mixin mob-lg {
        .cc-cp-foot {
            flex-direction: column;
            padding-bottom: 20px
        }
    }
}
@media only screen and (max-width: 480px) {
    .termsfeed-com---nb .cc-nb-okagree, .termsfeed-com---nb .cc-nb-reject, .termsfeed-com---nb .cc-nb-changep {
        justify-content: space-between !important;
    }

    .cc-nb-buttons-container button {
        width: auto !important;
    }

    .termsfeed-com---pc-dialog {
        margin: 0;
        max-width: 100vw;
    }


    .termsfeed-com---palette-dark.termsfeed-com---nb .cc-nb-main-container {
        padding: 1.5em 1rem !important;
    }
}
.cc-nb-text, .cc-nb-title, .cc-pc-head-title-text, .cc-pc-head-title-headline, .cc-cp-foot-save, .cc-cp-foot-byline, .cc-cp-body-tabs-item-link, .cc-cp-body-content-entry > *, .cc-custom-checkbox>label {
    font-family: var(--font-main) !important;
}


.termsfeed-com---palette-dark.termsfeed-com---nb {
    background: var(--white) !important;
    border: 1px solid var(--tuna);
    border-radius: 20px 0 0 0;

    .cc-nb-main-container {
        padding: 1.5em 2rem !important;
    }

    .cc-nb-title, .cc-nb-text {
        color: var(--tuna) !important;
    }

    .cc-nb-text {
        margin-bottom: 0;
    }

    .cc-nb-buttons-container {
        button {
            align-items: center;
            display: inline-flex;
            font-family: var(--font-main) !important;
            border: 1px solid var(--tuna);
            background-color: var(--white);
            border-radius: 20px;
            text-transform: capitalize;
            margin-bottom: 0 !important;
            margin-top: 13px !important;
            padding: 4px 5px 4px 9px;

            transition: .3s ease;
            color: var(--tuna);

            &::after {
                content: '';
                margin-left: 10px;
                border-radius: 7px;
                width: 29px;
                height: 29px;
                transition: .3s ease;
                background-position: center;
                background-size: contain;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%23058ED9' rx='14.5'/%3E%3Cpath stroke='%23080223' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
            }

            &:hover {
                background-color: var(--golden);
                color: var(--tuna);

                &::after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'%3E%3Crect width='29' height='29' fill='%23058ED9' rx='14.5'/%3E%3Cpath stroke='%23fff' stroke-width='2.5' d='m9.116 20.811 10.695-10.695M8 9.347l11.424.73.73 11.424'/%3E%3C/svg%3E");
                    transform: unset;
                }
            }
        }
    }
}

@mixin mob-lg {
    .choices {
        .choices__item--selectable {
            font-size: 14px;
        }
    }

    input {
        &,
        &::placeholder {
            font-size: 14px;
        }
    }
}

@mixin mob-sm {
    .container {
        padding: 0 15px;
    }
}
