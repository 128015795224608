.quiz {
    $gap: ac(98px, 50px);
    margin-top: $gap;

    &__title {
        margin-bottom: ac(37px, 25px);
        font-size: ac(37px, 28px);
	    line-height: ac(50px, 34px);
    }

    .first__grid-head {
        padding: ac(30px, 20px);
        
        .first__btn {
            margin-top: auto;

            span {
                transform: rotate(90deg);
            }
        }

        &.open {
			.first__btn {
				span {
					transform: rotate(0deg);
				}
			}
        }
    }

    .first__grid-head, .first__grid-content {
        background-color: var(--white);
        border: 1px solid var(--tuna);
    }

    .first__grid-content {
        &.open {
            margin-bottom: 15px;
        }
    }
}