.details {
    $gap: ac(60px, 30px);
    margin-top: $gap;
}

.details--article {
    $gap: ac(70px, 30px);
    margin-top: $gap;
}

.details {
    &--article {
        .details {
            &__soc-box {
                margin-top: ac(80px, 30px);
            }
        }
    }

    &__wrapper {
        display: flex;
        margin-bottom: ac(151px, 75px);
    }

    &__col {
        max-width: ac(809px, 610px, 1024, 1440);
        margin-right: 15px;
        flex: 0 0 auto;

        .btn {
            margin-top: ac(29px, 20px);
        }
    }

    &__text-box {
        a {
            color: var(--golden);

            &:hover {
                color: var(--blue);
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &:not(:last-child) {
                margin-bottom: ac(20px, 15px);
            }

            &:not(:first-child) {
                margin-top: ac(41px, 20px);
            }
        }

        h4,
        h5,
        h6 {
            font-weight: 600;
        }

        h4 {
            font-size: ac(34px, 26px);
            line-height: ac(44px, 36px);
        }
        
        h5 {
            font-size: ac(32px, 24px);
            line-height: ac(42px, 34px);
        }
        
        h6 {
            font-size: ac(27px, 22px);
            line-height: ac(32px, 26px);
        }

        p {
            line-height: 22px;
        }

        p, ul, ol {
            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }

        ul, ol {
            li {
                position: relative;
                padding-left: 26px;
                margin-bottom: 10px;
            }
        }

        ul {
            li {
                &::before {
                    content: '';
                    position: absolute;
                    left: 12px;
                    top: ac(9px, 7px);
                    border-radius: 100%;
                    width: 4px;
                    height: 4px;
                    background-color: var(--tuna);
                }
            }
        }

        ol {
            list-style: none;
            counter-reset: my-counter;

            li {
                counter-increment: my-counter;

                &::before {
                    content: counter(my-counter) ". ";
                    position: absolute;
                    left: 0px;
                    color: var(--tuna);
                }
            }
        }
    }

    &__soc-box {
		display: flex;
		flex-direction: column;
        margin-top: ac(41px, 30px);

		& > span {
			width: 100%;
			font-weight: 600;
			font-size: ac(21px, 16px);
			line-height: ac(25px, 19px);
		}
	}

    &__soc-row {
		display: flex;
		flex-wrap: wrap;
		margin-top: 20px;
		margin-right: ac(-20px, -18px);
		margin-bottom: -10px;
	}

    &__soc {
		display: flex;
		justify-content: center;
		width: ac(40px, 35px);
		height: ac(40px, 35px);
		background-color: var(--golden);
		border-radius: 100%;
		margin-right: ac(20px, 18px);
		margin-bottom: 10px;

		span {
			font-size: ac(23px, 19px);
			line-height: ac(40px, 35px);
			transition: .3s ease;

			&.icon-twitter {
				font-size: ac(21px, 16px);
			}
		}

		&:hover {
			background-color: var(--blue);

			span {
				color: var(--tuna);
			}
		}
	}

    &__sidebar {
        margin-top: ac(-115px, 0px, 1024, 1440);
    }

    &__sticky {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    &__circle {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: ac(200px, 30px, 1024, 1440);
        background-color: var(--alice);
        padding: 40px;
        width: ac(397px, 300px, 1024, 1440);
        min-height: ac(397px, 300px, 1024, 1440);
    }

    &__photo {
        position: relative;
        width: 190px;
        height: 161px;

        img {
            border-radius: 80px;
        }

        .details__soc {
            position: absolute;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);

            &:hover {
                background-color: var(--blue);
            }
        }
    }

    &__name {
        margin-top: 7px;
        font-weight: 600;
        font-size: ac(27px, 18px);
        line-height: ac(37px, 24px);
    }

    &__position {
        margin-top: 8px;
        margin-bottom: 15px;
        font-weight: 600;
        font-size: ac(21px, 18px);
    }

    &__link {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        font-family: var(--font-sec);

        &:not(:last-child) {
            margin-bottom: 4px;
        }

        &:hover {
            color: var(--blue);
        }
    }

    &__slider-box {
        display: flex;
        flex-wrap: wrap;
        margin-top: ac(39px, 25px);
    }

    .slider-nav {
        margin: 20px auto 0;
    }

    &__slide {
        .positions__card {
            height: 100%;
            width: 100%;
        }
    }

    @mixin tab {
        &__sidebar {
            margin-bottom: ac(40px, 20px);
        }

        &__circle {
            margin: 0 auto;
            width: 100%;
            max-width: 400px;
        }

        &__wrapper {
            flex-direction: column-reverse;
            margin-top: ac(60px, 35px);
        }

        &__photo {
            border-radius: 5px;
            width: 100%;
            max-width: ac(403px, 340px, 375, 1024);
            height: ac(191px, 164px, 375, 1024);
        }

        &__col {
            margin-right: 0;
            max-width: 100%;
        }
    }
}